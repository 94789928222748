export const filterSelectReducer = (state = null, action) => {
  if (action.type === 'FILTER_SELECT_ACTION') {
    return action.payload;
  }
  return state;
}

export const defaultFilterSelectReducer = (state = null, action) => {
  if (action.type === 'DEFAULT_FILTER_SELECT_ACTION') {
    return action.payload;
  }
  return state;
}

export const priceRangeDataReducer = (state = null, action) => {
  if (action.type === 'PRICE_RANGE_DATA_ACTION') {
    return action.payload;
  }
  return state;
}

export const excludeOOSReducer = (state = true, action) => {
  if (action.type === 'EXCLUDE_OOS_ACTION') {
    return action.payload;
  }
  return state;
}

export const bestSellerReducer = (state = false, action) => {
  if (action.type === 'BESTSELLER_ACTION') {
    return action.payload;
  }
  return state;
}