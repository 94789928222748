
export const convertToModule = (styles, classes) => {
	let result = "";
	if (classes) {
		result = `${classes} `;
		let classArr = classes && classes.length > 0 && classes.split(" ");
		for (let i = 0; classArr[i]; i++) {
			if (styles[classArr[i]]) {
				result += `${styles[classArr[i]]} `
			}
		}
	}
	return result;
}

export const getPageNameFromUrl = (url) => {
	let result = "";
	if (url) {
		if (url.indexOf("/sv/") > -1 || url.indexOf("/pk/") > -1) {
			result = "pdp"
		} else if (url.indexOf("/reviews/") > -1) {
			result = "reviews"
		} else if (url.indexOf("catPageType=") > -1) {
			result = "topcategories"
		} else if (url.indexOf("navKey=CP-") > -1
			|| url.indexOf("navKey=CL-") > -1
			|| url.indexOf("navKey=SCT-") > -1
			|| url.indexOf("navKey=CBL-") > -1
			|| url.indexOf("navKey=BR-") > -1) {
			result = "plp"
		}
	}
	return result;
}

//https://img6.hkrtcdn.com/cdn-cgi/image/width=300.0,height=300.0/31088/prd_3108755-Gritzo-SuperMilk-Weight-for-47-Yrs-Personalized-Protein-Nutrition-Drink-Mix-400-g-Double-Chocolate-47-Yrs-BoyGirl-Weight_o.png
export const convertImageCF = (url, height, width) => {
	if (url && url.indexOf("hkrtcdn.com/") > -1) {
		let splitURl = url.split("hkrtcdn.com/")
		return (`${splitURl[0]}hkrtcdn.com/cdn-cgi/image/width=${width},height=${height}/${splitURl[1]}`)
	}
	return url;
}

