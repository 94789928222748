export const showAddressFormReducer = (state = null, action) => {
    if (action.type === "SHOW_ADDRESS_FORM") {
        return action.payload
    }
    return state;
}

export const addressFormDataReducer = (state = null, action) => {
    if (action.type === "ADDRESS_FORM_DATA") {
        return action.payload
    }
    return state
}

export const addressListReducer = (state = null, action) => {
    if (action.type === "ADDRESS_LIST") {
        return action.payload
    }
    return state
}
export const ActiveAdddressReducer = (state = null, action) => {
    if (action.type === "ACTIVE_ADDRESS") {
        return action.payload
    }
    return state
}
export const addressFromTypeReducer = (state = null, action) => {
        if (action.type === "ADDRESS_FORM_TYPE") {
        return action.payload
    }
    return state;
}
