

const PRODUCT_DATA_DEFAULT = {
	product_id: "",
	name: "",
	coupon: "",
	category: "",
	brand: "",
	variant: "",
	price: 0,
	quantity: 1,
	position: 1,
	currency: "INR",
	discount: 0,
	item_category2: "",
	item_category3: "",
	item_category4: "",
	item_category5: "",
	item_list_id: "",
	item_list_name: "",
	location_id: ""
}


var RudderStackAnalytics = {
	SEND_EVENT: function (eventName, properties = {}, extId) {
		try {
			let _userData = JSON.parse(localStorage.getItem('userData')) || {};
			let _externalId = {
				externalId: [{
					type: "ga4ClientId",
					id: localStorage.getItem('deviceId') || ""
				}],
			}
			if (window.rudderanalytics) {
				if (eventName === 'identify') {
					window.rudderanalytics.identify(_userData.uH, properties);
					if (properties.firstLogin) {
						window.rudderanalytics.track("sign_up", { method: "Google" }, _externalId);
					} else {
						window.rudderanalytics.track("login", { method: "Google" }, _externalId);
					}
				} else if (eventName === 'page') {
					window.rudderanalytics.page();
				} else {
					window.rudderanalytics.track(eventName, properties, extId ? _externalId : {});
				}
			}	
		} catch (error) {
			console.log("ERROR TRACKING RUDDERANALYTICS: ", error);
		}
	},

	PREPARE_PRODUCT_EVENT: function (eventName, properties = {}, productData, returnData) {
		let eventData = { ...PRODUCT_DATA_DEFAULT, ...properties };
		if (productData) {
			let productDataMerged = { products: [{ ...PRODUCT_DATA_DEFAULT, ...productData }] };
			eventData = {
				...properties,
				...productDataMerged
			}
		}
		if(returnData){
			return eventData;
		}else{
			this.SEND_EVENT(eventName, eventData, true);
		}
	}
}
export default RudderStackAnalytics;