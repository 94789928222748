export const cartOrderSummaryReducer = (state=null,action) =>{
    if(action.type === "CART_ORDER_SUMMARY"){
        return action.payload;
    }
    return state;
    
}

export const cartVariantReducer = (state=null,action) =>{
    if(action.type === "CART_VARIANT"){
        return action.payload;
    }
    return state;
    
}

export const cartPackReducer = (state=null,action) =>{
    if(action.type === "CART_PACK"){
        return action.payload;
    }
    return state;
    
}

export const cartOfferReducer = (state=null,action) =>{    
    if(action.type === "CART_OFFER"){
        return action.payload;
    }
    return state;
    
}

export const paymentOfferReducer = (state=null,action) =>{    
    if(action.type === "PAYMENT_OFFER"){
        return action.payload;
    }
    return state;
    
}

export const cartAppliedOfferReducer = (state=null,action) =>{    
    if(action.type === "CART_APPLIED_OFFER"){
        return action.payload;
    }
    return state;
    
}

export const cartHeaderSummaryReducer = (state=null,action) =>{    
    if(action.type === "CART_HEADER_SUMMARY"){
        return action.payload;
    }
    return state;
    
}

export const cartAddressReducer = (state=null,action) =>{    
    if(action.type === "CART_ADDRESS"){
        return action.payload;
    }
    return state;
    
}


export const appliedMBCashChangeReducer = (state=null,action) => {
    if(action.type === "MB_CASH_TOGGLE"){
        return action.payload;
    }
    return state;
}
export const orderSummaryPopUpReducer = (state=null,action) => {
    if(action.type === "ORDER_SUMMARY_POPUP"){
        return action.payload;
    }
    return state;
}