const initState = {
    serverLoggedIn: false,
    isMobile: false,
    serverRequestQuery: ""
};

export const serverReducers = (state = initState, action) => {
    switch (action.type) {
        case "LOGGEDIN_ACTION":
            return { ...state, serverLoggedIn: action.loggedIn };
        case "REQUEST_QUERY_ACTION":
            return { ...state, serverRequestQuery: action.requestQuery };
        default:
            return state;
    }
}