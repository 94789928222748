export const isLogedInReducer = (state=null,action)=>{

    if(action.type==="IS_LOGEDIN_ACTION")
        return action.payload;

    return state;
};

export const isAssumeReducer = (state=null,action)=>{
    if(action.type==="IS_ASSUME_ACTION")
        return action.payload;

    return state;
};

export const isReferEarnPopupShownReducer = (state=null,action)=>{
    if(action.type==="IS_REFER_EARN_POPUP_SHOWN")
        return action.payload;

    return state;
};

export const compareItemCountReducer = (state=null,action)=>{
    if(action.type==="COMPARE_ITEM_COUNT_ACTION")
        return action.payload;

    return state;
};

export const comparePopupReducer = (state=null,action)=>{
    if(action.type==="COMPARE_POPUP_ACTION")
        return action.payload;

    return state;
};

export const tbRedeemPopUpReducer = (state=null,action)=>{
    if(action.type==="TB_REDEEM_POPUP_ACTION_SHOW")
        return action.payload;

    return state;
};

export const tbReferAndEarnTnCReducer = (state=null,action)=>{
    if(action.type==="TB_REFER_AND_EARN_TNC_ACTION")
        return action.payload;

    return state;
};

export const tbConcernNameReducer = (state=null,action)=>{
    if(action.type==="TB_CONCERN_NAME_ACTION")
        return action.payload;

    return state;
};

export const tbUserTabReducer = (state=null,action)=>{
    if(action.type==="TB_USER_TAB_ACTION")
        return action.payload;

    return state;
};

export const tbsCategoriesReducer = (state=null,action)=>{
    if(action.type==="TBS_CATEGORIES_ACTION")
        return action.payload;

    return state;
};
export const userDetailReducer = (state=null,action)=>{
    if(action.type==="USER_DETAIL_ACTION")
        return action.payload;

    return state;
};