import HK from 'factory/modules/HK';
export const getVrntIdFrmUrl = (str) => {
    if (str.length > 0) {
        str = str.split("navKey=");
        str = str[1].split("&");
        str = str[0];
        return str;
    }
    else {

        return null
    }
}
export const saveRecentSearchData = (searchStr) => {//function for saving recent search data on press enter button
    var searchValueAndSId = {};
    searchValueAndSId.term = searchStr;
    searchValueAndSId.sessionID = "55C43293EBA4D3878054A6A4811B9282";
    if (searchValueAndSId.term !== null || searchValueAndSId.term !== undefined || searchValueAndSId.term !== '')
        var reqObj = {
            method: "post",
            data: searchValueAndSId,
            url: HK.config.APIS.saveRecentSearch
        }
    HK.ajaxCall(reqObj).then(res => { }).catch(err => { });
}
export const capturePacVarPost = (en, pmId, pt, sgType, sgValue, kwd, kwSrch, varID) => {
    var dataO = {};
    var eventdata = {};
    kwd = concatName(kwd);
    //if(kwd.indexOf('Rs.')>-1){kwd=$.trim(kwd.substr(0,kwd.lastIndexOf('Rs')));}else{kwd=$.trim(kwd);}
    eventdata.pageType = pt;
    eventdata.keyword = kwd.replace(/\s\s+/g, ' ');
    eventdata.keywordSearched = kwSrch;
    eventdata.zeroSearch = false;
    eventdata.entityId = varID;
    eventdata.suggestionType = sgType;
    eventdata.suggestionValue = sgValue;
    dataO.eventName = en;
    let _url;
    if (!HK.loggedIn()) {
        dataO.userId = 0;
        _url = HK.config.APIS.eventTrack
    }
    else {
        dataO.userId = HK.loggedIn();
        _url = HK.config.APIS.V3EventTrack
    }
    dataO.userAgent = navigator.userAgent;
    dataO.eventData = eventdata;
    let reqObj = {
        data: {data: dataO, userId: HK.loggedIn() || 0},
        method: "post",
        url: _url
    }
    HK.ajaxCall(reqObj).then((res) => {

    }).catch((error) => {

    });
}

export const concatName = (kwd) => {
    if (kwd.indexOf('Rs.') > -1) { kwd = kwd.substr(0, kwd.lastIndexOf('Rs')).trim(); } else { kwd = kwd.trim(); }
    return kwd;
}

export const captureCatPost = function (en, pmId, pt, sgType, sgValue, kwd, kwSrch, cat, srchtrm) {
    var dataO = {};
    var eventdata = {};
    kwd = concatName(kwd);
    //if(kwd.indexOf('Rs.')>-1){kwd=$.trim(kwd.substr(0,kwd.lastIndexOf('Rs')));}else{kwd=$.trim(kwd);}

    eventdata.pageType = pt;
    eventdata.keyword = kwd.replace(/\s\s+/g, ' ');
    eventdata.keywordSearched = kwSrch;
    eventdata.zeroSearch = false;
    eventdata.category = cat;
    eventdata.termSearched = srchtrm;
    eventdata.suggestionType = sgType;
    eventdata.suggestionValue = sgValue;
    dataO.eventName = en;
    let _url;
    if (!HK.loggedIn()) {
        dataO.userId = 0;
        _url = HK.config.APIS.eventTrack
    }
    else {
        dataO.userId = HK.loggedIn();
        _url = HK.config.APIS.V3EventTrack
    }
    dataO.userAgent = navigator.userAgent;
    dataO.eventData = eventdata;

    let reqObj = {
        data: {data: dataO, userId: HK.loggedIn() || 0},
        method: "post",
        url: _url
    }
    HK.ajaxCall(reqObj).then((res) => {

    }).catch((error) => {

    });
}

export const remSrch = (txt) => {
    if (!HK.loggedIn()) {
        if (HK.isMobile()) {
            localStorage.removeItem('rcnt-srch');
        } else {
            var stoSrchs = JSON.parse(localStorage.getItem('rcnt-srch'));
            for (var i = 0; i < stoSrchs.length - 1; i++) {
                if(stoSrchs[i].term === txt){
                    stoSrchs.splice(i,1);
                }
            }
            localStorage.setItem('rcnt-srch', JSON.stringify(stoSrchs));
        }
    }
    else {/*user login*/
        // var txtJ = {};
        // txtJ.clearValue = txt;
        // txtJ.clearType = "event";

        // let reqObj = {
        //     url: '/api/search/clearSearchResults',
        //     method: 'post',
        //     data: txtJ
        // }

        // HK.ajaxCall(reqObj).then(res => { }).catch((error) => { });

    }
}

export const savSrch = (srchkey, catName, url, en, pt, sgType, sgValue, kwd, kwSrch, cat, srchtrm, vId) => {
    var dataO = {};
    var eventdata = {};
    eventdata.pageType = pt;
    eventdata.keyword = (kwd.replace(/\s\s+/g, ' ').trim());
    eventdata.keywordSearched = kwSrch;
    eventdata.zeroSearch = false;
    if (cat !== null && cat !== "") {
        eventdata.category = cat;
    }
    if (srchtrm !== null && srchtrm !== "") {
        eventdata.termSearched = srchtrm;
    }
    if (vId !== null && vId !== "") {
        eventdata.entityId = vId;
    }
    if (sgType !== null && sgType >= 0) {
        var intSgType = parseInt(sgType,10);
        if (!isNaN(intSgType)) {
            eventdata.suggestionType = intSgType;
        }
    }
    if (sgValue !== null && sgValue !== "") {
        eventdata.suggestionValue = sgValue;
    }
    dataO.eventName = en;
    if (!HK.loggedIn()) {
        dataO.userId = 0
    }
    else {
        dataO.userId = HK.loggedIn();
    }
    dataO.userAgent = navigator.userAgent;
    dataO.eventDate = new Date();
    dataO.eventData = eventdata;

    var srck = {};
    srchkey = srchkey.replace(/\s\s+/g, ' ');
    if (srchkey.indexOf('Rs.') > -1) { srck.term = (srchkey.substr(0, srchkey.lastIndexOf('Rs').trim())); } else { if (catName === null) { srck.term = srchkey.trim(); } else { srck.term = srchkey.trim() + " "; } }
    srck.catName = catName;
    srck.url = url;
    srck.data = dataO;
    srchkey = srck.term;


    if (!HK.loggedIn()) {
        if (localStorage.getItem('rcnt-srch') === null || localStorage.getItem('rcnt-srch') === 'null') {
            var rcntSrch = [];

            rcntSrch[0] = srck;
            localStorage.setItem('rcnt-srch', JSON.stringify(rcntSrch));
        }
        else {
            var storedSrchs = JSON.parse(localStorage.getItem('rcnt-srch')), vflg = 0;
            for (var k = 0; k < storedSrchs.length; k++) {
                if (srchkey.toLowerCase() === storedSrchs[k].term.toLowerCase()) { vflg = 1; break; }
            }
            if (vflg === 0) {
                if (storedSrchs.length < 5) {

                    storedSrchs[storedSrchs.length] = srck;
                    localStorage.setItem('rcnt-srch', JSON.stringify(storedSrchs));
                }
                else {
                    for (var i = 0; i < 4; i++) {
                        storedSrchs[i] = storedSrchs[i + 1];
                    }

                    storedSrchs[4] = srck;
                    localStorage.setItem('rcnt-srch', JSON.stringify(storedSrchs));
                }
            } else {
                remSrch(k);
                storedSrchs = JSON.parse(localStorage.getItem('rcnt-srch'));
                if(storedSrchs !== null)
                    storedSrchs.push(srck);
                localStorage.setItem('rcnt-srch', JSON.stringify(storedSrchs));
            }
        }
    }
    else {
        let _url = HK.config.APIS.V3EventTrack
        let reqObj = {
            data: { data: dataO, userId: HK.loggedIn() || 0 },
            method: "post",
            url: _url
        }
        HK.ajaxCall(reqObj).then((res) => {

        }).catch((error) => {
            console.log(error)
        });
    }
}
export const captureRcnt = (term) =>{
    let l_store = JSON.parse(localStorage.getItem('rcnt-srch'));
    let n_store ;
    for (let i=0;i<l_store.length;i++){
        if(l_store[i].term === term){
            n_store = l_store[i];
            l_store.splice(i,1);
        }
    }
    l_store.push(n_store);
    localStorage.setItem('rcnt-srch',JSON.stringify(l_store));
}