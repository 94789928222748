export const singleLineHeaderReducer = (state = null, action) => {
    if (action.type === "SHOW_SINGLE_LINE_HEADER") {
        return action.payload;
    }
    return state;
}

export const singleLineHeaderLinkToReducer = (state = null, action) => {
    if (action.type === "SINGLE_LINE_HEADER_LINK_TO") {
        return action.payload;
    }
    return state;
}


export const headerTextReducer = (state = null, action) => {
    if (action.type === "HEADER_TEXT_ACTION")
        return action.payload;

    return state;
}

export const showHeaderIconsReducer = (state=null, action) => {
    if (action.type === "SHOW_HEADER_ICONS_ACTION")
        return action.payload;

    return state;
}