import { concatName } from 'components/common/search/searchModule';
import HK from 'factory/modules/HK';
import UniversalAnalytics from './UniversalAnalytics';
import { __getTabTitle, __localStorageGet } from 'factory/modules/HK_service';
import { Analytics as Pinpoint } from '@aws-amplify/analytics';
import { Auth } from '@aws-amplify/auth';

const ISPROD = process.env.NEXT_PUBLIC_CLICKSTREAM_ENV === 'production' || false;
let customScreenViewPages = [
	"pack", "storeVariant", "menuLanding"
]
function getDateandTime() {
	let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	let d = new Date();
	let date = (d.getDate() < 10 ? '0' : '') + d.getDate();
	let month = monthNames[d.getMonth()];
	let year = d.getFullYear();
	let hours = (d.getHours() < 10 ? '0' : '') + d.getHours();
	let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
	let formattedDate = date + '-' + month + '-' + year + '  ' + hours + ':' + minutes;
	return formattedDate;
}
const Analytics = {
	init: function () {
		if (document.readyState == "complete") {
			//onload event allready fired
			this._loadThirdPartyJS();
		}
		else {
			//onload event not fired , attach functions to onLoad event
			this._postLoadFunction(this._loadThirdPartyJS);
		}
	},
	_loadThirdPartyJS: function () {
		const headEle = document.getElementsByTagName('meta')[0];
		const cdn = process.env.NEXT_PUBLIC_CDN_URLS.split(',')[0];
		//Analytics._loadCriteo(headEle, cdn);
		// Analytics._loadClickStream(headEle, cdn);
		Analytics._loadClickStreamNew(headEle, cdn);
		//this._loadWebEngage();
		// this._loadAdmitAd(headEle);
		// init Branch
		// Analytics._loadAdobe(headEle);
		//Analytics._loadGoogleAdsConversionTag(headEle, HK.config.googlsAdsConversionIdSuper);
		//Analytics._loadGoogleAdsConversionTag(headEle, HK.config.googlsAdsConversionIdMB);
		Analytics.branchIo.init();
	},
	_postLoadFunction: function (a) {
		if ("undefined" != typeof (window.attachEvent)) {
			return window.attachEvent("onload", a)
		}
		else {
			if (window.addEventListener) {
				return window.addEventListener("load", a, false)
			}
		}
	},
	// Load WebEngage
	_loadWebEngage: function () {
		let webengage = window.webengage;
		if (typeof webengage !== 'undefined') {
			webengage.init('d3a4a764');
			webengage.options('webpush.registerServiceWorker', false);
			this.webEngage().screen(window.pageType);
		}
	},
	// Load ClickStream
	_loadClickStreamNew: function (headEle, cdn) {
		let $this = this;
		/* ClickStream poolid:
			Prod:ap-south-1:9f98bf67-fac8-4916-b0a1-15ad48938be5
			Beta:ap-south-1:98ff9672-07a8-45f6-9f9f-51d98e600651
			dev/qa:ap-south-1:b15e8b4d-de7e-4278-91b3-5a4b8e2dddf3
		*/
		const amplifyConfig = {
			Auth: {
				identityPoolId: process.env.NEXT_PUBLIC_CLICKSTREAM_POOL_ID || 'ap-south-1:b15e8b4d-de7e-4278-91b3-5a4b8e2dddf3',
				region: 'ap-south-1'
			}
		}
		//Initialize Amplify
		Auth.configure(amplifyConfig);
		/* ClickStream AppId:
			Prod:ef12c9eff9584a35ac8d73b3f542748f
			Beta:ccc5beafe2c74789a1b4fbcd4b09f32f
			dev/qa:d73e246019d24d5db88c33156c3bc3da
		*/
		const analyticsConfig = {
			AWSPinpoint: {
				// Amazon Pinpoint App Client ID
				appId: process.env.NEXT_PUBLIC_CLICKSTREAM_ID || 'd73e246019d24d5db88c33156c3bc3da',
				// Amazon service region
				region: 'ap-south-1',
				mandatorySignIn: false,
			}
		}
		Pinpoint.configure(analyticsConfig);

		let __aws_windowObj = {
			client: analyticsConfig,
			aws: function () { }
		}

		window.__analytics.aws = __aws_windowObj;
		let __initData = $this.ClickStream().defaults();
		let deviceId = localStorage.getItem('deviceId');
		if (!deviceId) {
			let deviceId;
			let tempDeviceId = localStorage.getItem('tempDeviceId')
			if (tempDeviceId) {
				deviceId = tempDeviceId
				localStorage.removeItem('tempDeviceId')
			} else {
				deviceId = Math.floor(Math.random() * Math.pow(16, 15)).toString(16);
			}
			__initData.deviceId = deviceId;
			localStorage.setItem('deviceId', deviceId);
			//$this.ClickStream().push('INSTALL', __initData);

			$this.ClickStream().push('FIRST_VISIT', __initData);
		}
		if (customScreenViewPages.indexOf(window.pageType) === -1) {
			//$this.ClickStream().push('SCREEN_VIEW', __initData);
			$this.ClickStream().push('PAGE_LOAD', __initData);
		}
		window.__analytics.standBy.aws.map(item => {
			if (item.data.deviceId) {
				return $this.ClickStream().push(item.event, item.data);
			} else {
				item.data.deviceId = __initData.deviceId
				return $this.ClickStream().push(item.event, item.data);
			}

		});
	},
	// Load ClickStream
	// _loadClickStream: function (headEle, cdn) {
	// 	let $this = this;
	// 	let script1 = document.createElement('script');
	// 	script1.async = true;
	// 	script1.src = cdn + '/static/external/aws/aws-sdk-custom.min.js';
	// 	script1.onload = function () {
	// 		let script2 = document.createElement('script');
	// 		script2.defer = true;
	// 		script2.src = cdn + '/static/external/aws/aws-sdk-mobile-analytics.min.js';
	// 		script2.onload = function () {
	// 			let AWS = window.AWS;
	// 			let AMA = window.AMA;
	// 			if (typeof AWS === 'undefined' || typeof AMA === 'undefined')
	// 				return;
	// 			AWS.config.region = 'ap-south-1';
	// 			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	// 				IdentityPoolId: 'ap-south-1:b15e8b4d-de7e-4278-91b3-5a4b8e2dddf3'
	// 			});
	// 			/* CliskStream AppId:
	// 				Prod:1674b9192100428f9403407ab7edcc92
	// 				Beta:862c24d1315d428f83ab8bf52e006f96
	// 				dev/qa:4a143941b69d48f7878a9274c24185be
	// 			*/
	// 			let __aws_windowObj = {
	// 				client: new AMA.Manager({
	// 					// appId: process.env.NEXT_PUBLIC_CLICKSTREAM_ID || 'd73e246019d24d5db88c33156c3bc3da',
	// 					appId: "d73e246019d24d5db88c33156c3bc3da",
	// 					sessionLength: 1800000
	// 				}),
	// 				aws: function () { }
	// 			}
	// 			window.__analytics.aws = __aws_windowObj;
	// 			let __initData = $this.ClickStream().defaults();
	// 			let deviceId = localStorage.getItem('deviceId');
	// 			if (!deviceId) {
	// 				let deviceId;
	// 				let tempDeviceId = localStorage.getItem('tempDeviceId')
	// 				if (tempDeviceId) {
	// 					deviceId = tempDeviceId
	// 					localStorage.removeItem('tempDeviceId')
	// 				} else {
	// 					deviceId = Math.floor(Math.random() * Math.pow(16, 15)).toString(16);
	// 				}
	// 				__initData.deviceId = deviceId;
	// 				localStorage.setItem('deviceId', deviceId);
	// 				//$this.ClickStream().push('INSTALL', __initData);

	// 				$this.ClickStream().push('FIRST_VISIT', __initData);
	// 			}
	// 			if (customScreenViewPages.indexOf(window.pageType) === -1) {
	// 				//$this.ClickStream().push('SCREEN_VIEW', __initData);
	// 				$this.ClickStream().push('PAGE_LOAD', __initData);
	// 			}
	// 			window.__analytics.standBy.aws.map(item => {
	// 				if (item.data.deviceId) {
	// 					return $this.ClickStream().push(item.event, item.data);
	// 				} else {
	// 					item.data.deviceId = __initData.deviceId
	// 					return $this.ClickStream().push(item.event, item.data);
	// 				}

	// 			});
	// 		}
	// 		headEle.parentNode.insertBefore(script2, headEle);
	// 	}
	// 	headEle.parentNode.insertBefore(script1, headEle);
	// },
	// Load AdmitAd
	_loadAdmitAd: function (headEle) {
		let script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.artfut.com/static/tagtag.min.js?campaign_code=8bb47ae6b0';
		script.onerror = 'var self = this;window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers=ADMITAD.Helpers||{},ADMITAD.Helpers.generateDomains=function(){for(var e=new Date,n=Math.floor(new Date(2020,e.getMonth(),e.getDate()).setUTCHours(0,0,0,0)/1e3),t=parseInt(1e12*(Math.sin(n)+1)).toString(30),i=["de"],o=[],a=0;a<i.length;++a)o.push({domain:t+"."+i[a],name:t});return o},ADMITAD.Helpers.findTodaysDomain=function(e){function n(){var o=new XMLHttpRequest,a=i[t].domain,D="https://"+a+"/";o.open("HEAD",D,!0),o.onload=function(){setTimeout(e,0,i[t])},o.onerror=function(){++t<i.length?setTimeout(n,0):setTimeout(e,0,void 0)},o.send()}var t=0,i=ADMITAD.Helpers.generateDomains();n()},window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers.findTodaysDomain(function(e){if(window.ADMITAD.dynamic=e,window.ADMITAD.dynamic){var n=function(){return function(){return self.src?self:""}}(),t=n(),i=(/campaign_code=([^&]+)/.exec(t.src)||[])[1]||"";t.parentNode.removeChild(t);var o=document.getElementsByTagName("head")[0],a=document.createElement("script");a.src="https://www."+window.ADMITAD.dynamic.domain+"/static/"+window.ADMITAD.dynamic.name.slice(1)+window.ADMITAD.dynamic.name.slice(0,1)+".min.js?campaign_code="+i,o.appendChild(a)}});';
		headEle.parentNode.insertBefore(script, headEle);
	},
	// Load Adobe
	// _loadAdobe: function (headEle) {
	// 	try {
	// 		let script = document.createElement('script');
	// 		script.src = "//www.everestjs.net/static/adc_iframe.js";
	// 		script.onload = function () {
	// 			window.__analytics.adobePixel = true;
	// 			const script2 = document.createElement('script');
	// 			script2.setAttribute('src', '//www.everestjs.net/static/amo-conversion-mapper.js');
	// 			document.body.appendChild(script2);
	// 			window.EF = window.EF || {};
	// 			if (window.EF.main) {
	// 				return;
	// 			}
	// 			if (!window.EF.jsTagAdded) {
	// 				var efjs = document.createElement('script');
	// 				efjs.type = 'text/javascript';
	// 				efjs.async = true;
	// 				efjs.src = 'https://www.everestjs.net/static/st.v3.js';
	// 				var s = document.getElementsByTagName('script')[0];
	// 				s.parentNode.insertBefore(efjs, s);
	// 				window.EF.jsTagAdded = 1;
	// 			}
	// 		}
	// 		headEle.parentNode.insertBefore(script, headEle);
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// },
	// AdmitAd
	AdmitAd: function () {
		return {
			getInvoiceBroker: function () {
				let source = "na";
				if (HK.Cookies.get("affiliateName") && HK.Cookies.get("affiliateName").indexOf('admitad') > -1) {
					source = 'adm';
				}
				/*if(HK.Cookies.get("affiliateName").indexOf('admitad') > -1){
					source = 'adm';
				};*/
				return source;
			},
			getOrderdItem: function (orderdata) {
				let orderedItem = [];
				orderdata.products.map((item) => {
					orderedItem.push({
						Product: {
							productID: item.sv_id, // internal product ID (not more than 100 characters). Not used for "Insurance and finance" program category
							category: '1',        // Do not change the value of it
							price: item.totHkPr, // pass total amount here
							priceCurrency: "INR", // currency code in the ISO-4217 alfa-3 format
						},
						orderQuantity: item.qty,    // product quantity. Always '1' for "Insurance and finance" program category
						additionalType: "sale"     // always sale
					});
					return '';
				})
				return orderedItem;
			},
			push: function (data) {
				let ADMITAD = window.ADMITAD || {};
				ADMITAD.Invoice = ADMITAD.Invoice || {};
				ADMITAD.Invoice.broker = this.getInvoiceBroker();      // Pass any other value if the conversion is getting done through another source
				ADMITAD.Invoice.category = "1";     // action code (already inserted)
				let orderedItem = this.getOrderdItem(data);
				if (orderedItem && orderedItem.length > 0) {
					ADMITAD.Invoice.referencesOrder = ADMITAD.Invoice.referencesOrder || [];
					ADMITAD.Invoice.referencesOrder.push({
						orderNumber: data.transactionId, // Pass order ID here
						orderedItem: orderedItem
					});
					// Important! If order data is loaded via AJAX, uncomment this string.
					if (ADMITAD.Tracking) {
						ADMITAD.Tracking.processPositions();
					}
				}
			}
		}
	},
	// ClickStream
	ClickStream: function () {
		return {
			defaults: function (ispers) {
				let _userData = JSON.parse(localStorage.getItem('userData'));
				let utmSource = '', utmMedium = '', utmCampaign = '';
				let uS = HK.Cookies.get("affiliateName");
				let uM = HK.Cookies.get("affiliateMedium");
				let uC = HK.Cookies.get("affiliateCampaign");
				if (uS && uS !== "undefined") {
					utmSource = uS;
				}
				if (uM && uM !== "undefined") {
					utmMedium = uM;
				}
				if (uC && uC !== "undefined") {
					utmCampaign = uC;
				}
				let _ref = '';
				if (!(document.referrer.indexOf('truebasics') > -1) && !(document.referrer.indexOf('healthkartqa.com') > -1)) {
					_ref = document.referrer;
				}
				/*if(document.referrer === '' || document.referrer === null){
				   localStorage.setItem('prevScreenName', window.pageType);
				   localStorage.setItem('currScreenName', window.pageType);	
				}
				else if(window.pageType !== localStorage.getItem('currScreenName'))
				{
				  prevPage=localStorage.getItem('currScreenName');
				  localStorage.setItem('prevScreenName', prevPage);
				  localStorage.setItem('currScreenName', window.pageType);
				}*/
				let neteffectiveType = '';
				let networkdownlink = '';
				if (navigator.connection) {
					neteffectiveType = (navigator.connection.effectiveType) ? navigator.connection.effectiveType : '';
					networkdownlink = (navigator.connection.downlink) ? navigator.connection.downlink + "Mb/s" : '';
				}

				let _defaults = {};
				let deviceId = localStorage.getItem('deviceId') || localStorage.getItem('tempDeviceId');
				if (!deviceId) {
					let temp = Math.floor(Math.random() * Math.pow(16, 15)).toString(16);
					localStorage.setItem('tempDeviceId', temp);
					// localStorage.setItem('deviceId', temp);
					deviceId = temp;
				}
				let tabTitle = '';
				if (window.location.href.indexOf('tabId=') > -1) {
					tabTitle = __getTabTitle() || '';
				}
				let traffic_source = HK.Cookies.get("traffic_source") || '';
				/* Redirection url */
				let redirection_url = sessionStorage.getItem('redirection_url') || '';
				if (!redirection_url) {
					redirection_url = window.location.href;
					sessionStorage.setItem('redirection_url', redirection_url)
				}
				/* Redirection Url */
				// let redirection_url = sessionStorage.getItem('redirection_url') || '';
				if (!redirection_url) {
					redirection_url = window.location.href;
					sessionStorage.setItem('redirection_url', redirection_url)
				}

				if (ispers) {
					_defaults = {
						campaign: (utmCampaign || '').slice(0, 199),
						date: HK.getDateandTime(),
						deviceId: deviceId,
						medium: (utmMedium || '').slice(0, 199),
						pageName: localStorage.getItem('prevScreenName'),
						id: localStorage.getItem('sourceId'),
						platform: HK.isMobile() ? 'Mobile' : 'Desktop',
						queryParam: window.pageType === HK.config.pageTypes.campaign.retailRefill ? "retail-refill-campaign-queryparam" : (window.location.search || '').slice(0, 199),
						referrer: window.pageType === HK.config.pageTypes.campaign.retailRefill ? "retail-refill-campaign-referrer" : (_ref || '').slice(0, 199),
						screenName: localStorage.getItem('currScreenName'),
						source: (utmSource || '').slice(0, 199),
						userId: 'guest',
						// email: '',
						// mobile: '',
						time: new Date().getTime(),
						path: window.location.pathname.slice(0, 199),
						url: window.location.href.slice(0, 199),
						tabTitle: tabTitle || '',
						traffic_source: (traffic_source || '').slice(0, 199),
						redirection_url: (redirection_url || '').slice(0, 199)
					}
				}
				else {
					_defaults = {
						campaign: (utmCampaign || '').slice(0, 199),
						carrier: "",
						date: getDateandTime(),
						deviceId: deviceId,
						deviceName: "",
						iPAddress: "",
						medium: (utmMedium || '').slice(0, 199),
						networkSpeed: networkdownlink,
						networkType: neteffectiveType,
						pageName: localStorage.getItem('prevScreenName'),
						platform: HK.isMobile() ? 'Mobile' : 'Desktop',
						queryParam: window.pageType === HK.config.pageTypes.campaign.retailRefill ? "retail-refill-campaign-queryparam" : (window.location.search || '').slice(0, 199),
						referrer: window.pageType === HK.config.pageTypes.campaign.retailRefill ? "retail-refill-campaign-referrer" : (_ref || '').slice(0, 199),
						screenName: localStorage.getItem('currScreenName'),
						source: (utmSource || '').slice(0, 199),
						userId: 'guest',
						// email: '',
						// mobile: '',
						OrderType: localStorage.getItem('Order_Type') ? localStorage.getItem('Order_Type') : "",
						time: new Date().getTime(),
						tabTitle: tabTitle || '',
						traffic_source: (traffic_source || '').slice(0, 199),
						redirection_url: (redirection_url || '').slice(0, 199)
					}
				}
				let _merger = {};
				if (_userData !== null) {
					_merger = {
						userId: _userData.uH,
						email: _userData.uM,
						mobile: _userData.contact
					}
				}
				return { ..._defaults, ..._merger }
			},
			push: function (event, data) {
				const keyword = __localStorageGet('keyword');
				if (keyword && !data.keyword) {
					data.keyword = keyword
				}
				data.queryParam = data.queryParam && data.queryParam.slice(0, 199) || '';
				data.url = window.location.pathname.slice(0, 199);
				/* tab title */
				let tabTitle = '';
				if (data.queryParam && data.queryParam.indexOf('tabId=') > -1) {
					tabTitle = __getTabTitle() || '';
				}
				data.tabTitle = tabTitle;
				/* traffic source */
				let traffic_source = HK.Cookies.get("traffic_source") || '';
				data.traffic_source = (traffic_source || '').slice(0, 199);
				const _eventDataNew = {
					name: event,
					attributes: data
				}
				if (typeof window.__analytics !== 'undefined') {
					if (typeof window.__analytics.aws.client !== 'undefined')
						Pinpoint.record(_eventDataNew);
					// window.__analytics.aws.client.recordEvent(event, data);Pinpoint
					else
						window.__analytics.standBy.aws.push({ event: event, data: data })
				}
			},
		}
	},
	// WebEngage
	webEngage: function () {
		return {
			screen: function (pageType) {
				if (typeof window.webengage !== 'undefined')
					window.webengage.screen(pageType);
			},
			loginSignup(args) {
				let _userData = JSON.parse(localStorage.getItem('userData'));
				if (typeof window.webengage !== 'undefined' && _userData !== null) {
					window.webengage.user.login(args.userId);
					window.webengage.user.setAttribute({
						we_email: _userData.uM,
						we_phone: _userData.contact,
						we_gender: _userData.uG,
						we_first_name: _userData.firstName,
						we_birth_date: _userData.birthDt
					});
				}
			},
			logout() {
				if (typeof window.webengage !== 'undefined')
					window.webengage.user.logout();
			}
		}
	},
	/* conversion */
	googleAdsConversion: function () {
		function gtag() {
			window.dataLayer.push(arguments);
		}
		return {
			orderSuccess: function (data) {
				gtag(
					'event',
					'MB.com_sale_SuperMCC',
					{
						'send_to': `${HK.config.googlsAdsConversionIdSuper}/OX4eCKT5rsgBEOXFgdQD`,
						'value': data.transactionAmount,
						'currency': 'INR',
						'transaction_id': data.transactionId
					}
				);

				gtag(
					'event',
					'MB.com_Purchase_Hardcoded',
					{
						'send_to': `${HK.config.googlsAdsConversionIdMB}/xOIECMqA6M4BEJrn4N8C`,
						'value': data.transactionAmount,
						'currency': 'INR',
						'transaction_id': data.transactionId
					});
			},
			addToCartBuyNow_SuperMCC: function (data) {
				gtag(
					'event',
					'MB.com_addtocart_SuperMCC',
					{
						'send_to': `${HK.config.googlsAdsConversionIdSuper}/jVxiCIvc0MsBEOXFgdQD`,
						'value': data.price * data.quantity,
						'currency': 'INR',
						'variant_name': data.itemName,
						'variant_price': data.price,
						'variant_id': data.variantId,
						'secondary_category': data.category
					}
				)
			},
			addToCartBuyNow_MBMCC: function (data) {
				gtag(
					'event',
					'MB.com_addtocart_MBMCC',
					{
						'send_to': `${HK.config.googlsAdsConversionIdMB}/Eqw8CJTkkMwBEJrn4N8C`,
						'value': data.price * data.quantity,
						'currency': 'INR',
						'variant_name': data.itemName,
						'variant_price': data.price,
						'variant_id': data.variantId,
						'secondary_category': data.category
					}
				)
			},
			begincheckout_SuperMCC: function (data) {
				let obj = {
					'send_to': `${HK.config.googlsAdsConversionIdSuper}/loVyCPL-tMsBEOXFgdQD`,
					...data
				}
				gtag(
					'event',
					'MB.com_begincheckout_SuperMCC',
					obj
				);

			},
			begincheckout_MBMCC: function (data) {
				let obj = {
					'send_to': `${HK.config.googlsAdsConversionIdMB}/viFNCPbRrMwBEJrn4N8C`,
					...data
				}

				gtag(
					'event',
					'MB.com_begincheckout_MBMCC',
					obj
				);
			},
		}
	},
	// Adobe
	// Adobe: function () {
	// 	var initData = function (transactionProps) {
	// 		try {
	// 			var f = function (transactionProps) {
	// 				const script = document.querySelector('script[src="//www.everestjs.net/static/amo-conversion-mapper.js"]');
	// 				if (script) {
	// 					script.setAttribute('adc_iframe', window.location.href);
	// 				}
	// 				window.EF.init({
	// 					eventType: "transaction",
	// 					transactionProperties: transactionProps,
	// 					segment: "",
	// 					searchSegment: "",
	// 					sku: "",
	// 					userid: HK.config.adobeId,
	// 					pixelHost: "pixel.everesttech.net",
	// 					allow3rdPartyPixels: 1
	// 				});
	// 				window.EF.main();
	// 			};
	// 			window.EF = window.EF || {};
	// 			window.EF.adcloud_iframe = window.location.href;
	// 			if (window.EF.main) {
	// 				f(transactionProps);
	// 				return;
	// 			}
	// 			window.EF.onloadCallbacks = window.EF.onloadCallbacks || [];
	// 			window.EF.onloadCallbacks[window.EF.onloadCallbacks.length] = () => f(transactionProps);
	// 		} catch (e) {
	// 			console.log(e)
	// 		}
	// 	}
	// 	return {
	// 		viewItem: function () {
	// 			initData('ev_product_m=1')
	// 		},
	// 		viewList: function () {
	// 			initData('ev_listing_m=1')
	// 		},
	// 		buyNowAddTocart: function () {
	// 			initData('ev_addcart_m=1')
	// 		},
	// 		viewCart: function () {
	// 			initData('ev_cart_m=1')
	// 		},
	// 		orderSuccess: function ({ revenue, transactionId }) {
	// 			initData(`ev_purchase=1&ev_sale=${revenue}&ev_transid=${transactionId}`)
	// 		},
	// 		checkout: function () {
	// 			initData('ev_check_m=1')
	// 		},
	// 	}
	// },
	// Scopes
	Scope: function () {
		const $this = this;
		return {
			// Screen View
			screenView: function () {
				//$this.ClickStream().push('SCREEN_VIEW', $this.ClickStream().defaults());
				$this.ClickStream().push('PAGE_LOAD', $this.ClickStream().defaults());
				// 	$this.webEngage().screen(window.pageType);
			},
			// Login
			// login: function (args) {
			// 	$this.ClickStream().push('LOGIN', { ...$this.ClickStream().defaults(), ...args });
			// 	$this.webEngage().loginSignup(args);
			// },
			login: function (args) {
				$this.ClickStream().push('LOGIN_SUCCESS', { ...$this.ClickStream().defaults(), ...args });
				$this.webEngage().loginSignup(args);
			}
			,
			// Signup
			signup: function (args) {
				$this.ClickStream().push('SIGNUP', { ...$this.ClickStream().defaults(), ...args });
				$this.webEngage().loginSignup(args);
			},
			// Logout
			logout: function () {
				$this.ClickStream().push('LOGOUT', { ...$this.ClickStream().defaults() });
			},
			// Search
			search: function (searchTerm, resultCount) {
				let searchData = {
					keyword: searchTerm,
					itemName: concatName(searchTerm).replace(/\s\s+/g, ' '),
					quantity: HK.getIntOrEmpty(resultCount) || 0,
					searchAction: localStorage.getItem('searchAction') || 'Enter',
					eventName: HK.config.eventNames.SEARCH_INPUT_QTY || ""
				}
				$this.ClickStream().push('SEARCH', { ...$this.ClickStream().defaults(), ...searchData });
			},
			//categoryView
			categoryView: function (variantRes, navKey) {
				// let data = {
				// 	categoryId: navKey,
				// 	itemName: (variantRes && variantRes.categories !== undefined && variantRes.categories !== null && variantRes.categories.length > 0 ? variantRes.categories.join(',') : '')
				// }
				//$this.ClickStream().push('CATEGORY_CLICKED', { ...$this.ClickStream().defaults(), ...data });
				$this.ClickStream().push('PAGE_LOAD', { ...$this.ClickStream().defaults() });
				// $this.Adobe().viewList();
			},

			_createEventData: function (data, isCombo, stock, filter) {
				let productOBJ = []
				let ItemObj = []
				if (isCombo || data.isPack) {
					if (data.packSv && data.packSv.length > 0) {
						let tempFormatedData = []
						data.packSv.map(eachPackSV => {
							let formatedPackData = { ...eachPackSV, brName: data.brName }
							tempFormatedData.push(formatedPackData)
						})
						data = tempFormatedData
					}
					if (data.comboData && data.comboData.length > 0) {
						let comboData = []
						data.comboData.map(eachComboData => {
							comboData.push(eachComboData.sv_bsc)
						})
						data = comboData
					}
					data.map((eachData, index) => {
						let tempProductData = {
							'name': eachData.name || eachData.nm || '',
							'id': eachData.id || 0,
							'price': parseInt(eachData.offerPrice, 10) || parseInt(eachData.offer_pr, 10) || 0,
							'brand': eachData.brName || '',
							'category': eachData.catName || data.category || '',
							'variant': '',
							'position': index + 1 || '',
							'dimension6': "Srp Page",
							'dimension7': eachData.oos ? "Out_of_Stock" : "In_Stock",
							'dimension8': eachData.discount || 0,
							'dimension9': filter || '',
							'dimension10': 'Add To Cart Flow',
							source: window.pageType,
						}

						let tempItemData = {
							...tempProductData,
							'quantity': 1,
							'index': eachData.ind || 1,
							'item_list_name': "Srp Page",
						}

						productOBJ.push(tempProductData)
						ItemObj.push(tempItemData)
					})
					return { product: productOBJ, item: ItemObj }
				} else {
					let tempProductData = {
						'name': data.name || data.nm || '',
						'id': data.id || 0,
						'price': parseInt(data.offerPrice, 10) || parseInt(data.offer_pr, 10) || 0,
						'brand': data.brName || '',
						'category': data.catName || data.category || '',
						'variant': '',
						'position': data.ind + 1 || '',
						'dimension6': "Srp Page",
						'dimension7': stock ? "Out_of_Stock" : "In_Stock",
						'dimension8': data.discount || 0,
						'dimension9': filter || '',
						'dimension10': 'Add To Cart Flow',
						source: window.pageType,
					}

					let tempItemData = {
						...tempProductData,
						'quantity': 1,
						'index': data.ind || 1,
						'item_list_name': "Srp Page",
					}
					return { product: [tempProductData], item: [tempItemData] }
				}
			},


			buyNowAddTocart: function (variantData, eventType, passedQuantity) {
				if (variantData) {
					let quantity = localStorage.getItem("quantity")
					let obj;
					if (variantData.packSv && variantData.packSv.length > 0) {
						let ComboDataOBJ = []
						variantData.packSv.map(eachSVData => {
							let obj = {
								variantId: eachSVData.id,
								itemName: eachSVData.name,
								itemPosition: eachSVData.itemPosition || '',
								category: eachSVData.catName || eachSVData.category,
								price: eachSVData.offerPrice,
								quantity: passedQuantity ? HK.getIntOrEmpty(passedQuantity) : 1,
								brand: eachSVData.brName,
								url: window.location.href,
								productImageUrl: eachSVData.img,
								widgetName: eachSVData.widgetName,
								widgetPosition: eachSVData.widgetPosition || "",
								eventName: eachSVData.eventName || ""
							}
							ComboDataOBJ.push(obj)
						})
						obj = ComboDataOBJ
					}
					else {
						obj = {
							variantId: variantData.id,
							itemName: variantData.name,
							itemPosition: variantData.itemPosition || '',
							category: variantData.catName || variantData.category,
							price: variantData.offerPrice,
							quantity: passedQuantity ? HK.getIntOrEmpty(passedQuantity) : 1,
							brand: variantData.brName,
							url: window.location.href,
							productImageUrl: variantData.img,
							widgetName: variantData.widgetName,
							widgetPosition: variantData.widgetPosition || "",
							eventName: variantData.eventName || ""
						}

					}
					if (eventType === 'AddTocart') {
						localStorage.setItem('Order_Type', 'AddTocart_PLP');
						$this.Scope().AddTocart(obj);
						$this.googleAdsConversion().addToCartBuyNow_SuperMCC(obj);
						$this.googleAdsConversion().addToCartBuyNow_MBMCC(obj);
					}
					else if (eventType === 'GotoCart') {
						localStorage.setItem('Order_Type', 'GotoCart_PLP');
						$this.Scope().GotoCart(obj);
					}
					else {
						localStorage.setItem('Order_Type', 'BuyNow_PLP');
						$this.Scope().buyNow(obj);
						$this.googleAdsConversion().addToCartBuyNow_SuperMCC(obj);
						$this.googleAdsConversion().addToCartBuyNow_MBMCC(obj);
					}
					let filter = '';
					if (HK.isMobile()) {
						filter = sessionStorage.getItem("mobilefilter")
					} else {
						filter = sessionStorage.getItem("filterClicked");
					}
					if (eventType === "AddTocart" || eventType === "AddCombo") {
						if (window.pageType === "menuLanding" || window.pageType === "search" || window.pageType === "clearance" || window.pageType === "brandCatalog" || eventType === "AddCombo" || window.pageType === 'cart' || window.pageType === 'search' || window.pageType === 'MyAccount') {
							let stock = eventType === "AddCombo" ? '' : variantData.oos
							let EcomDataLayerproductclick = {
								'event': 'LS_MB_productClick',
								'ecommerce': {
									'click': {
										'actionField': { 'list': window.pageType === "search" ? "Search Result" : 'Category Page' },
										'products': this._createEventData(eventType === "AddCombo" ? variantData : variantData, eventType === "AddCombo", stock, filter).product,
										'items': this._createEventData(eventType === "AddCombo" ? variantData : variantData, eventType === "AddCombo", stock, filter).item,
									}
								}
							}
							window.dataLayer.push(EcomDataLayerproductclick)
							let EventName = {
								'event': 'LS_MB_productDetail',
								'ecommerce': {
									'detail': {
										'actionField': { 'list': window.pageType === "search" ? "Search Result" : 'Category Page' },
										'products': this._createEventData(eventType === "AddCombo" ? variantData : variantData, eventType === "AddCombo", stock, filter).product,
										'items': this._createEventData(eventType === "AddCombo" ? variantData : variantData, eventType === "AddCombo", stock, filter).item,
									}
								}

							}
							window.dataLayer.push(EventName)
							let addtocart = {
								'event': 'LS_MB_addToCart',
								'ecommerce': {
									'currencyCode': 'INR',
									'add': {
										'products': this._createEventData(eventType === "AddCombo" ? variantData : variantData, eventType === "AddCombo", stock, filter).product,
										'items': this._createEventData(eventType === "AddCombo" ? variantData : variantData, eventType === "AddCombo", stock, filter).item,
									}
								}
							}
							window.dataLayer.push(addtocart)
							localStorage.setItem("Flow", "Add To Cart Flow")
						} else if (window.pageType === "home") {
							let stock = variantData.oos
							let EcomDataLayerproductclick = {
								'event': 'LS_MB_productClick',
								'ecommerce': {
									'click': {
										'actionField': { 'list': "Home Page" },
										'products': [{
											'name': variantData.name || '',
											'id': variantData.id || 0,
											'price': parseInt(variantData.offerPrice, 10) || 0,
											'brand': variantData.brName || '',
											'category': variantData.category || '',
											'variant': '',
											'position': variantData.ind || '',
											'dimension6': "Home Page",
											'dimension7': stock ? "Out_of_Stock" : "In_Stock",
											'dimension8': variantData.discount || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Add To Cart Flow',
										}],
										'items': [{
											'item_name': variantData.name || '',
											'item_id': variantData.id || 0,
											'price': parseInt(variantData.offerPrice, 10) || 0,
											'item_brand': variantData.brName || '',
											'item_category': variantData.category || '',
											'item_variant': '',
											'quantity': 1,
											'index': variantData.ind || '',
											'position': variantData.ind || '',
											'dimension6': "Home Page",
											'item_list_name': "Home Page",
											'dimension7': stock ? "Out_of_Stock" : "In_Stock",
											'dimension8': variantData.discount || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Add To Cart Flow',
										}]
									}
								}
							}
							window.dataLayer.push(EcomDataLayerproductclick)
							let EventName = {
								'event': 'LS_MB_productDetail',
								'ecommerce': {
									'detail': {
										'actionField': { 'list': "Home Page" },
										'products': [{
											'name': variantData.name || '',
											'id': variantData.id || 0,
											'price': parseInt(variantData.offerPrice, 10) || 0,
											'brand': variantData.brName || '',
											'category': variantData.category || '',
											'variant': '',
											'dimension6': "Home page",
											'dimension7': stock ? "Out_of_Stock" : "In_Stock",
											'dimension8': variantData.discount || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Add To Cart Flow'
										}],
										'items': [{
											'item_name': variantData.name || '',
											'item_id': variantData.id || 0,
											'price': parseInt(variantData.offerPrice, 10) || 0,
											'item_brand': variantData.brName || '',
											'item_category': variantData.category || '',
											'item_variant': '',
											'quantity': 1,
											'item_list_name': "Home page",
											'dimension6': "Home page",
											'dimension7': stock ? "Out_of_Stock" : "In_Stock",
											'dimension8': variantData.discount || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Add To Cart Flow'
										}]
									}
								}

							}
							window.dataLayer.push(EventName)
							let addtocart = {
								'event': 'LS_MB_addToCart',
								'ecommerce': {
									'currencyCode': 'INR',
									'add': {
										'products': [{
											'name': variantData.name || '',
											'id': variantData.id || 0,
											'price': variantData.offerPrice || 0,
											'brand': variantData.brName || '',
											'category': "Home Page",
											'variant': '',
											'quantity': 1,
											'dimension6': "Home Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Add To Cart Flow'
										}],
										'items': [{
											'item_name': variantData.name || '',
											'item_id': variantData.id || 0,
											'price': variantData.offerPrice || 0,
											'item_brand': variantData.brName || '',
											'item_category': "Home Page",
											'item_variant': '',
											'quantity': 1,
											'item_list_name': "Home Page",
											'dimension6': "Home Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Add To Cart Flow'
										}]
									}
								}
							}
							window.dataLayer.push(addtocart)
							localStorage.setItem("Flow", "Add To Cart Flow")
						}
						else if (window.pageType === "storeVariant") {
							let addtocart = {
								'event': 'LS_MB_addToCart',
								'ecommerce': {
									'currencyCode': 'INR',
									'add': {
										'products': [{
											'name': variantData.name || variantData.nm || '',
											'id': variantData.id || 0,
											'price': variantData.offerPrice || variantData.offer_pr || 0,
											'brand': variantData.brName || '',
											'category': variantData.eventCategry === "SRP Page" ? variantData.catName : variantData.category,
											'variant': '',
											'quantity': quantity || 1,
											'dimension6': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Normal Flow'
										}],
										'items': [{
											'item_name': variantData.name || variantData.nm || '',
											'item_id': variantData.id || 0,
											'price': variantData.offerPrice || variantData.offer_pr || 0,
											'item_brand': variantData.brName || '',
											'item_category': variantData.eventCategry === "SRP Page" ? variantData.catName : variantData.category,
											'item_variant': '',
											'quantity': quantity || 1,
											'item_list_name': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension6': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Normal Flow'
										}]
									}
								}
							}
							window.dataLayer.push(addtocart)
							localStorage.setItem("Flow", "Normal Flow")

							if (variantData.event_name === 'YOU_MAY_LIKE') {
								let stock = eventType === "AddCombo" ? '' : variantData.oos
								let filter = '';
								if (HK.isMobile()) {
									filter = sessionStorage.getItem("mobilefilter")
								} else {
									filter = sessionStorage.getItem("filterClicked");
								}

								let EcomDataLayerproductclick = {
									'event': 'LS_MB_productClick',
									'ecommerce': {
										'click': {
											'actionField': { 'list': window.pageType === "search" ? "Search Result" : 'Category Page' },
											'products': this._createEventData(variantData, eventType === "AddCombo", stock, filter).product,
											'items': this._createEventData(variantData, eventType === "AddCombo", stock, filter).item,
										}
									}
								}
								window.dataLayer.push(EcomDataLayerproductclick)
								let EventName = {
									'event': 'LS_MB_productDetail',
									'ecommerce': {
										'detail': {
											'actionField': { 'list': window.pageType === "search" ? "Search Result" : 'Category Page' },
											'products': this._createEventData(variantData, eventType === "AddCombo", stock, filter).product,
											'items': this._createEventData(variantData, eventType === "AddCombo", stock, filter).item,
										}
									}

								}
								window.dataLayer.push(EventName)

							}
						} else if (window.pageType === "pack") {
							let addtocart = {
								'event': 'LS_MB_addToCart',
								'ecommerce': {
									'currencyCode': 'INR',
									'add': {
										'products': [{
											'name': variantData.name || '',
											'id': variantData.id || 0,
											'price': variantData.offerPrice || 0,
											'brand': variantData.brName || '',
											'category': variantData.eventCategry === "SRP Page" ? variantData.catName : variantData.category,
											'variant': '',
											'quantity': quantity || 1,
											'dimension6': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Normal Flow'
										}],
										'items': [{
											'item_name': variantData.name || '',
											'item_id': variantData.id || 0,
											'price': variantData.offerPrice || '',
											'item_brand': variantData.brName || '',
											'item_category': variantData.eventCategry === "SRP Page" ? variantData.catName : variantData.category,
											'item_variant': '',
											'quantity': quantity,
											'item_list_name': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension6': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Normal Flow'
										}]
									}
								}
							}
							window.dataLayer.push(addtocart)
							localStorage.setItem("Flow", "Normal Flow")
							if (variantData.event_name === 'YOU_MAY_LIKE') {
								let stock = eventType === "AddCombo" ? '' : variantData.oos
								let filter = '';
								if (HK.isMobile()) {
									filter = sessionStorage.getItem("mobilefilter")
								} else {
									filter = sessionStorage.getItem("filterClicked");
								}

								let EcomDataLayerproductclick = {
									'event': 'LS_MB_productClick',
									'ecommerce': {
										'click': {
											'actionField': { 'list': window.pageType === "search" ? "Search Result" : 'Category Page' },
											'products': this._createEventData(eventType === "AddCombo" ? variantData.packSv : variantData, eventType === "AddCombo", stock, filter).product,
											'items': this._createEventData(eventType === "AddCombo" ? variantData.packSv : variantData, eventType === "AddCombo", stock, filter).item,
										}
									}
								}
								window.dataLayer.push(EcomDataLayerproductclick)
								let EventName = {
									'event': 'LS_MB_productDetail',
									'ecommerce': {
										'detail': {
											'actionField': { 'list': window.pageType === "search" ? "Search Result" : 'Category Page' },
											'products': this._createEventData(eventType === "AddCombo" ? variantData.packSv : variantData, eventType === "AddCombo", stock, filter).product,
											'items': this._createEventData(eventType === "AddCombo" ? variantData.packSv : variantData, eventType === "AddCombo", stock, filter).item,
										}
									}

								}
								window.dataLayer.push(EventName)

							}
						}


					} else if (eventType === "BuyNow") {
						if (window.pageType === "menuLanding" || window.pageType === "search" || window.pageType === "clearance" || window.pageType === "brandCatalog" || window.pageType === 'cart' || window.pageType === 'search') {
							let stock = variantData.oos
							let EcomDataLayerproductclick = {
								'event': 'LS_MB_productClick',
								'ecommerce': {
									'click': {
										'actionField': { 'list': window.pageType === "search" ? "Search Result" : 'Category Page' },
										'products': this._createEventData(variantData, eventType === "AddCombo" ? true : false, stock, '').product,
										'items': this._createEventData(variantData, eventType === "AddCombo" ? true : false, stock, '').item,
									}
								}
							}
							window.dataLayer.push(EcomDataLayerproductclick)
							let EventName = {
								'event': 'LS_MB_productDetail',
								'ecommerce': {
									'detail': {
										'actionField': { 'list': window.pageType === "search" ? "Search Result" : 'Category Page' },
										'products': this._createEventData(variantData, eventType === "AddCombo" ? true : false, stock, '').product,
										'item': this._createEventData(variantData, eventType === "AddCombo" ? true : false, stock, '').item,
									}
								}

							}
							window.dataLayer.push(EventName)
							let addtocart = {
								'event': 'LS_MB_addToCart',
								'ecommerce': {
									'currencyCode': 'INR',
									'add': {
										'products': this._createEventData(variantData, eventType === "AddCombo" ? true : false, stock, '').product,
										'item': this._createEventData(variantData, eventType === "AddCombo" ? true : false, stock, '').item,
									}
								}
							}
							window.dataLayer.push(addtocart)
							localStorage.setItem("Flow", "Buy Now Flow")
						}
						else if (window.pageType === "storeVariant") {
							let addtocart = {
								'event': 'LS_MB_addToCart',
								'ecommerce': {
									'currencyCode': 'INR',
									'add': {
										'products': [{
											'name': variantData.name,
											'id': variantData.id,
											'price': variantData.offerPrice,
											'brand': variantData.brName,
											'category': variantData.eventCategry === "SRP Page" ? variantData.catName : variantData.category,
											'variant': '',
											'quantity': quantity,
											'dimension6': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Buy Now Flow'
										}],
										'items': [{
											'item_name': variantData.name,
											'item_id': variantData.id,
											'price': variantData.offerPrice,
											'item_brand': variantData.brName,
											'item_category': variantData.eventCategry === "SRP Page" ? variantData.catName : variantData.category,
											'item_variant': '',
											'quantity': quantity,
											'item_list_name': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension6': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Buy Now Flow'
										}]
									}
								}
							}
							window.dataLayer.push(addtocart)
							localStorage.setItem("Flow", "Buy Now Flow")
						}
						else if (window.pageType === "pack") {
							let addtocart = {
								'event': 'LS_MB_addToCart',
								'ecommerce': {
									'currencyCode': 'INR',
									'add': {
										'products': [{
											'name': variantData.name || '',
											'id': variantData.id || 0,
											'price': variantData.offerPrice || 0,
											'brand': variantData.brName || '',
											'category': variantData.eventCategry === "SRP Page" ? variantData.catName || '' : variantData.category || '',
											'variant': '',
											'quantity': quantity || 1,
											'dimension6': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Buy Now Flow'
										}],
										'items': [{
											'item_name': variantData.name || '',
											'item_id': variantData.id || 0,
											'price': variantData.offerPrice || 0,
											'item_brand': variantData.brName || '',
											'item_category': variantData.eventCategry === "SRP Page" ? variantData.catName || '' : variantData.category || '',
											'item_variant': '',
											'quantity': quantity || 1,
											'item_list_name': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension6': variantData.eventCategry === "SRP Page" ? "SRP Page" : "Detail Page",
											'dimension7': variantData.oos ? "Out_of_Stock" : "In_Stock",
											'dimension8': (variantData && variantData.discount) || 0,
											'dimension9': filter === null ? "" : filter,
											'dimension10': 'Buy Now Flow'
										}]
									}
								}
							}
							window.dataLayer.push(addtocart)
							localStorage.setItem("Flow", "Buy Now Flow")
						}
					}
				}
			},
			AddTocart: function (obj) {
				$this.ClickStream().push('ADD_TO_CART', { ...$this.ClickStream().defaults(), ...obj });
				// $this.Adobe().buyNowAddTocart();
			},
			buyNow: function (obj) {
				$this.ClickStream().push('BUY_NOW', { ...$this.ClickStream().defaults(), ...obj });
				// $this.Adobe().buyNowAddTocart();
			},
			GotoCart: function (obj) {
				$this.ClickStream().push('GO_TO_CART', { ...$this.ClickStream().defaults(), ...obj });
			},
			Address: function (evt, address, addmob, altmob) {
				let data = {};
				data.address = address;
				data.addressMobile = addmob;
				data.alternateMobile = altmob;
				$this.ClickStream().push(evt, { ...$this.ClickStream().defaults(), ...data });
			},
			wishlistData: function (action, data) {
				let obj = {};
				obj.price = data.offerPrice;
				obj.variantId = data.id;
				obj.packId = '';
				if (data.url.indexOf('pk/') > -1) {
					obj.packId = data.id;
					obj.variantId = '';
				}
				obj.itemName = data.name;
				let eventType = "ADD_TO_WISHLIST";
				if (action === 'remove') { eventType = "REMOVE_WISHLIST"; }
				$this.ClickStream().push(eventType, { ...$this.ClickStream().defaults(), ...obj });
			},
			AddtoCartWishlist: function () {
			},
			NotifyWishlist: function () {
			},
			submitProfile: function () {
			},
			paymentSelect: function (totalValue) {
				let data = {};
				data.totalValue = totalValue;
				data.paymentMode = localStorage.getItem('paymentMode');
				$this.ClickStream().push('PAYMENT_SELECT', { ...$this.ClickStream().defaults(), ...data });
			},
			paymentSuccess: function (odata) {
				let products = [];
				let items = [];
				let btnflow = localStorage.getItem('Flow')
				let filter = '';
				if (HK.isMobile()) {
					filter = sessionStorage.getItem("mobilefilter")
				} else {
					filter = sessionStorage.getItem("filterClicked");
				}
				let dimension11 = odata.paymentMode
				odata.products.map((d, i) => {
					let name = d.sv_nm
					let id = d.sv_id
					let price = d.totLP
					let brand = d.brand
					let category = d.catName
					let variant = "";
					let quantity = d.qty
					let coupon = ''
					let dimension6 = ""
					let dimension7 = 'Instock'
					let dimension8 = d.discount
					let dimension9 = filter || ''
					let dimension10 = btnflow;
					return products.push({ name, id, price, brand, category, variant, quantity, coupon, dimension6, dimension7, dimension8, dimension9, dimension10, dimension11 })
				})
				odata.products.map((d, i) => {
					let item_name = d.sv_nm
					let item_id = d.sv_id
					let price = d.totLP
					let item_brand = d.brand
					let item_category = d.catName
					let item_variant = "";
					let quantity = d.qty
					let coupon = ''
					let dimension6 = ""
					let dimension7 = 'Instock'
					let dimension8 = d.discount
					let dimension9 = filter || ''
					let dimension10 = btnflow;
					return items.push({ item_name, item_id, price, item_brand, item_category, item_variant, quantity, coupon, dimension6, dimension7, dimension8, dimension9, dimension10, dimension11 })
				})
				let data = {};
				data.brand = odata.brand;
				data.category = odata.category;
				data.leafNode = odata.leafNode;
				data.itemName = odata.name;
				data.variantId = odata.variantId;
				data.packId = odata.packId;
				data.paymentMode = odata.paymentMode;
				data.shippingPrice = odata.shippingPrice;
				data.totalValue = odata.totalValue;
				data.transactionId = odata.transactionId;
				data.personalizedConversion = false;
				data.quantity = odata.quantity
				if (HK.Cookies.get("ispersResults")) {
					data.personalizedConversion = true;
				}
				//$this.ClickStream().push('PAYMENT_SUCCESS', { ...$this.ClickStream().defaults(), ...data });
				$this.ClickStream().push('ORDER_SUCCESS', { ...$this.ClickStream().defaults(), ...data });
				//$this.ClickStream().push('CART_UPDATE', { ...$this.ClickStream().defaults(), cartItems: 0 });
				localStorage.removeItem('paymentMode');
				localStorage.removeItem('Order_Type');
				// $this.Adobe().orderSuccess({ revenue: odata.totalValue, transactionId: odata.transactionId });
				$this.googleAdsConversion().orderSuccess({ transactionId: odata.transactionId, transactionAmount: odata.totalValue });
				let EventName = {
					'event': 'LS_MB_CheckoutComplete',
					'ecommerce': {
						'purchase': {
							'actionField': {
								'id': odata.transactionId,
								'affiliation': HK.Cookies.get("affiliateName") || '',
								'revenue': odata.totalValue,
								'tax': '0',
								'shipping': odata.shippingPrice,
								'coupon': ''
							},
							products,
							items
						}
					}
				}
				window.dataLayer.push(EventName)
			},
			cartofferRemove: function (offerName) {
				let data = {};
				data.offerName = offerName;
				$this.ClickStream().push('CART_OFFER_REMOVED', { ...$this.ClickStream().defaults(), ...data });
			},
			cartofferApply: function (offerName) {
				let data = {};
				data.offerName = offerName;
				$this.ClickStream().push('CART_OFFER_APPLIED', { ...$this.ClickStream().defaults(), ...data });
			},
			menuLandingBrandFilter: function () {
			},
			productClicked: function (productType, productdata, pagetp) {
				let data = {};
				if (productType === "storeVariant") {
					data.screenName = productType;
					data.variantId = productdata.id;
					data.itemName = productdata.nm;
					data.price = parseInt(productdata.offer_pr, 10);
					data.quantity = 1;
					data.brand = productdata.brName;
					data.leafNode = productdata.catName;
					data.primaryAttributeName = '';
					data.primaryAttributeValue = '';
					data.secondaryAttributeName = '';
					data.secondaryAttributeValue = '';
					data.stId = productdata.stId
					for (let at = 0; at < productdata.attr.length; at++) {
						if (at === 0) {
							data.primaryAttributeName = productdata.attr[at].dis_nm;
							data.primaryAttributeValue = productdata.selAttr[productdata.attr[at].nm];
						}
						if (at === 1) {
							data.secondaryAttributeName = productdata.attr[at].dis_nm;
							data.secondaryAttributeValue = productdata.selAttr[productdata.attr[at].nm];
						}
					}
					data.url = window.location.href;
					data.vendorName = productdata.vendorName;
					//HK._dataLayer(, pageType);
					//data.productImageUrl=this.layerData.variantImageM;
					// $this.Adobe().viewItem(data.url);
				}
				if (productType === "pack") {
					//console.log('pack found')
					data.screenName = productType;
					data.packId = productdata.packs.id;
					data.itemName = productdata.packs.nm;
					data.category = '';
					data.price = productdata.packs.offer_pr;
					data.quantity = 1;
					data.brand = '';
					data.leafNode = '';
					data.primaryAttributeName = '';
					data.primaryAttributeValue = '';
					data.secondaryAttributeName = '';
					data.secondaryAttributeValue = '';
					data.url = window.location.href;
					data.vendorName = '';
					data.productImageUrl = productdata.packs.image.m_link;
					data.stId = productdata.stId
					data.stId = productdata.stId;
					// $this.Adobe().viewItem(data.url);
				}
				$this.ClickStream().push('PAGE_LOAD', { ...$this.ClickStream().defaults(), ...data });
				$this.ClickStream().push('PRODUCT_CLICKED', { ...$this.ClickStream().defaults(), ...data });
				//$this.ClickStream().push('PRODUCT_CLICKED', { ...$this.ClickStream().defaults(), ...data });
				let EcomDataLayer
				let stock = productdata.oos
				let filter = '';
				if (HK.isMobile()) {
					filter = sessionStorage.getItem("mobilefilter")
				} else {
					filter = sessionStorage.getItem("filterClicked");
				}
				if (pagetp === "menuLanding" || pagetp === "search" || pagetp === "brandCatalog") {
					EcomDataLayer = {
						'event': 'LS_MB_productClick',
						'ecommerce': {
							'click': {
								'actionField': { 'list': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')) },
								'products': [{
									'name': productdata.nm || '',
									'id': productdata.id || 0,
									'price': parseInt(productdata.offer_pr, 10) || 0,
									'brand': productdata.brName || '',
									'category': productdata.catName || '',
									'variant': '',
									'position': productdata.widgetProductIndex || 0,
									'dimension6': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')),
									'dimension7': stock ? "Out_of_Stock" : "In_Stock",
									'dimension8': productdata.discount || 0,
									'dimension9': filter || '',
									'dimension10': 'Normal Flow',
								}],
								'items': [{
									'item_name': productdata.nm || '',
									'item_id': productdata.id || 0,
									'price': parseInt(productdata.offer_pr, 10) || 0,
									'item_brand': productdata.brName || '',
									'item_category': productdata.catName || '',
									'item_variant': '',
									'quantity': 1,
									'index': productdata.widgetProductIndex || 1,
									'position': productdata.widgetProductIndex || 1,
									'item_list_name': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')),
									'dimension6': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')),
									'dimension7': stock ? "Out_of_Stock" : "In_Stock",
									'dimension8': productdata.discount || 0,
									'dimension9': filter || '',
									'dimension10': 'Normal Flow',
								}],
							}
						}
					}
					window.dataLayer.push(EcomDataLayer)
					let EventName = {
						'event': 'LS_MB_productDetail',
						'ecommerce': {
							'detail': {
								'actionField': { 'list': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')) },
								'products': [{
									'name': productdata.nm || '',
									'id': productdata.id || 0,
									'price': productdata.offer_pr || 0,
									'brand': productdata.brName || '',
									'category': productdata.catName || '',
									'variant': '',
									//'position': widgetPosition,
									'dimension6': "Detail page",
									'dimension7': stock ? "Out_of_Stock" : "In_Stock",
									'dimension8': productdata.discount,
									'dimension9': filter === null ? "" : filter,
									'dimension10': 'Normal Flow',
								}],
								'items': [{
									'item_name': productdata.nm || '',
									'item_id': productdata.id || 0,
									'price': productdata.offer_pr || 0,
									'item_brand': productdata.brName || '',
									'item_category': productdata.catName || '',
									'item_variant': '',
									'item_list_name': "Detail page",
									'dimension6': "Detail page",
									'dimension7': stock ? "Out_of_Stock" : "In_Stock",
									'dimension8': productdata.discount,
									'dimension9': filter === null ? "" : filter,
									'dimension10': 'Normal Flow',
								}]
							}
						}

					}
					window.dataLayer.push(EventName)
				} else {
					let indexPosition = sessionStorage.getItem("indexPosition")
					if (productType === "pack") {
						let packStock = productdata.packs.oos;
						EcomDataLayer = {
							'event': 'LS_MB_productClick',
							'ecommerce': {
								'click': {
									'actionField': { 'list': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')) },
									'products': [{
										'name': productdata.packs.nm || '',
										'id': productdata.packs.id || 0,
										'price': (productdata.packs.offer_pr) || 0,
										'brand': 'MuscleBlaze',
										'category': '',
										'variant': '',
										'position': indexPosition !== null ? indexPosition : "",
										'dimension6': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')),
										'dimension7': packStock ? "Out_of_Stock" : "In_Stock",
										'dimension8': productdata.packs.discount || 0,
										'dimension9': filter === null ? "" : filter,
										'dimension10': 'Normal Flow',
									}],
									'items': [{
										'item_name': productdata.packs.nm || '',
										'item_id': productdata.packs.id || 0,
										'price': (productdata.packs.offer_pr) || 0,
										'item_brand': 'MuscleBlaze',
										'item_category': '',
										'item_variant': '',
										'position': indexPosition !== null ? indexPosition : "",
										'item_list_name': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')),
										'dimension6': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')),
										'dimension7': packStock ? "Out_of_Stock" : "In_Stock",
										'dimension8': productdata.packs.discount || 0,
										'dimension9': filter === null ? "" : filter,
										'dimension10': 'Normal Flow',
									}]
								}
							}
						}
						window.dataLayer.push(EcomDataLayer)
						let EventName = {
							'event': 'LS_MB_productDetail',
							'ecommerce': {
								'detail': {
									'actionField': { 'list': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')) },
									'products': [{
										'name': productdata.packs.nm || '',
										'id': productdata.packs.id || 0,
										'price': (productdata.packs.offer_pr) || 0,
										'brand': 'MuscleBlaze',
										'category': '',
										'variant': '',
										'dimension6': "Detail page",
										'dimension7': stock ? "Out_of_Stock" : "In_Stock",
										'dimension8': productdata.packs.discount || 0,
										'dimension9': filter === null ? "" : filter,
										'dimension10': 'Normal Flow'
									}],
									'items': [{
										'item_name': productdata.packs.nm || '',
										'item_id': productdata.packs.id || 0,
										'price': (productdata.offer_pr) || 0,
										'item_brand': 'MuscleBlaze',
										'item_category': '',
										'item_variant': '',
										'dimension6': "Detail page",
										'item_list_name': "Detail page",
										'dimension7': stock ? "Out_of_Stock" : "In_Stock",
										'dimension8': productdata.packs.discount || 0,
										'dimension9': filter === null ? "" : filter,
										'dimension10': 'Normal Flow'
									}]

								}
							}

						}
						window.dataLayer.push(EventName)
					} else {
						EcomDataLayer = {
							'event': 'LS_MB_productClick',
							'ecommerce': {
								'click': {
									'actionField': { 'list': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')) },
									'products': [{
										'name': productdata.nm || '',
										'id': productdata.id || 0,
										'price': parseInt(productdata.offer_pr, 10) || 0,
										'brand': productdata.brName || '',
										'category': productdata.catName || '',
										'variant': '',
										'position': indexPosition !== null ? indexPosition : "",
										'dimension6': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')),
										'dimension7': stock ? "Out_of_Stock" : "In_Stock",
										'dimension8': productdata.discount || 0,
										'dimension9': filter === null ? "" : filter,
										'dimension10': 'Normal Flow',
									}],
									'items': [{
										'item_name': productdata.nm || '',
										'item_id': productdata.id || 0,
										'price': parseInt(productdata.offer_pr, 10) || 0,
										'item_brand': productdata.brName || '',
										'item_category': productdata.catName || '',
										'item_variant': '',
										'position': indexPosition !== null ? indexPosition : "",
										'item_list_name': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')),
										'dimension6': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')),
										'dimension7': stock ? "Out_of_Stock" : "In_Stock",
										'dimension8': productdata.discount || 0,
										'dimension9': filter === null ? "" : filter,
										'dimension10': 'Normal Flow',
									}]
								}
							}
						}
						window.dataLayer.push(EcomDataLayer)
						let EventName = {
							'event': 'LS_MB_productDetail',
							'ecommerce': {
								'detail': {
									'actionField': { 'list': pagetp === "menuLanding" ? "Category page" : (pagetp === "search" ? "Search Result" : (pagetp === "brandCatalog" ? "Category page" : 'Home Page')) },
									'products': [{
										'name': productdata.nm || '',
										'id': productdata.id || 0,
										'price': productdata.offer_pr || 0,
										'brand': productdata.brName || '',
										'category': productdata.catName || '',
										'variant': '',
										'dimension6': "Detail page",
										'dimension7': stock ? "Out_of_Stock" : "In_Stock",
										'dimension8': productdata.discount || 0,
										'dimension9': filter === null ? "" : filter,
										'dimension10': 'Normal Flow'
									}],
									'items': [{
										'item_name': productdata.nm || '',
										'item_id': productdata.id || 0,
										'price': productdata.offer_pr || 0,
										'item_brand': productdata.brName || '',
										'item_category': productdata.catName || '',
										'item_variant': '',
										'dimension6': "Detail page",
										'item_list_name': "Detail page",
										'dimension7': stock ? "Out_of_Stock" : "In_Stock",
										'dimension8': productdata.discount || 0,
										'dimension9': filter === null ? "" : filter,
										'dimension10': 'Normal Flow'
									}]

								}
							}

						}
						window.dataLayer.push(EventName)
					}
				}
			},
			createCartOnUpdateCart: function (Data) {
				let cartData = Data.cartPricing;
				let completeCartUpdateObj = {};
				completeCartUpdateObj['cartItems'] = 0;
				completeCartUpdateObj['offer'] = {};
				completeCartUpdateObj['cartValue'] = cartData.totPay;
				completeCartUpdateObj['totalValue'] = cartData.totMrp;
				let variantId = [], name = [], category = [], price = [], productImageUrl = [], brand = [], leafNode = [];
				let shoppingCartVariant = cartData.cartVar;
				for (let i = 0; i < shoppingCartVariant.length; i++) {
					let shoppingCartItemWebengage = {};
					shoppingCartItemWebengage.variantId = shoppingCartVariant[i].sv_id;
					shoppingCartItemWebengage.name = shoppingCartVariant[i].sv_nm;
					shoppingCartItemWebengage.category = shoppingCartVariant[i].prCatName;
					shoppingCartItemWebengage.leafNode = shoppingCartVariant[i].catName;
					shoppingCartItemWebengage.price = parseInt(shoppingCartVariant[i].totOffPr / shoppingCartVariant[i].qty, 10);
					shoppingCartItemWebengage.productImageUrl = shoppingCartVariant[i].pr_img.tlink;
					shoppingCartItemWebengage.quantity = parseInt(shoppingCartVariant[i].qty, 10);
					shoppingCartItemWebengage.brand = shoppingCartVariant[i].brand;
					shoppingCartItemWebengage.url = shoppingCartVariant[i].url;
					variantId.push(shoppingCartItemWebengage.variantId);
					leafNode.push(shoppingCartItemWebengage.leafNode);
					name.push(shoppingCartItemWebengage.name);
					category.push(shoppingCartItemWebengage.category);
					price.push(shoppingCartItemWebengage.price);
					productImageUrl.push(shoppingCartItemWebengage.productImageUrl);
					brand.push(shoppingCartItemWebengage.brand);
					completeCartUpdateObj['cartItems'] += 1;
				}
				var shoppingCartPacks = cartData.cartPacks;
				for (let i = 0; i < shoppingCartPacks.length; i++) {
					let pack = shoppingCartPacks[i];
					let shoppingCartPackItem = {};
					shoppingCartPackItem.variantId = pack.id;
					shoppingCartPackItem.quantity = parseInt(pack.qty, 10);
					shoppingCartPackItem.name = pack.pckName;
					shoppingCartPackItem.productImageUrl = pack.pckimage.tlink;
					shoppingCartPackItem.url = pack.url;
					shoppingCartPackItem.price = parseInt(pack.totOffPr / pack.qty, 10);
					variantId.push(pack.packId);
					price.push(pack.totOffPr / pack.qty);
					productImageUrl.push(shoppingCartPackItem.productImageUrl);
					name.push(shoppingCartPackItem.name);
					completeCartUpdateObj['cartItems'] += 1;
				}
				completeCartUpdateObj.variantId = variantId.join(',');
				completeCartUpdateObj.name = name.join(',');
				completeCartUpdateObj.leafNode = leafNode.join(',');
				completeCartUpdateObj.category = category.join(',');
				completeCartUpdateObj.price = price.join(',');
				completeCartUpdateObj.brand = brand.join(',');
				completeCartUpdateObj.productImageUrl = productImageUrl.join(',');
				if (cartData.appOfrDtl != null) {
					completeCartUpdateObj.offer = cartData.appOfrDtl.nm;
				}
				else {
					completeCartUpdateObj.offer = null;
				}
				//this.dynamicCartData = completeCartUpdateObj;
				return completeCartUpdateObj;
			},
			cartItemRemove: function () {
				//let data = {};
				//$this.ClickStream().push('CATEGORY_CLICKED', { ...$this.ClickStream().defaults(), ...data });
				//mobileAnalyticsClient.recordEvent('CART_UPDATE',that.createCartOnUpdateCart(cartData,completeCartUpdateObjTemp));
			},
			cartUpdate: function (Data) {
				if (!Data.cartPricing) { return false; }
				// let data = this.createCartOnUpdateCart(Data);
				//$this.ClickStream().push('CART_UPDATE', { ...$this.ClickStream().defaults(), ...data });
			},
			cartCouponApplied: function (couponCode) {
				let data = {};
				data.couponCode = couponCode;
				$this.ClickStream().push('CART_COUPON_APPLIED', { ...$this.ClickStream().defaults(), ...data });
				let _userData = JSON.parse(localStorage.getItem('userData'));
				let nv = window.nv;
				if (typeof (nv) !== 'undefined')
					nv('event', 'coupon_applied', {
						customer_name: _userData !== null ? _userData.firstName : '',
						coupon: couponCode,
						discount: '',
						status: 'success'
					}, 10, 2);
			},
			cartCouponRemove: function (couponCode) {
				let data = {};
				data.couponCode = couponCode;
				$this.ClickStream().push('CART_COUPON_REMOVED', { ...$this.ClickStream().defaults(), ...data });
			},
			blogView: function (data) {
				$this.ClickStream().push('HomePage_Diet_ClickListener', { ...$this.ClickStream().defaults(), ...data });
			},
			cartBogoApplied: function (offerName) {
				let data = {};
				data.offerName = offerName;
				$this.ClickStream().push('CART_BOGO_APPLIED', { ...$this.ClickStream().defaults(), ...data });
			},
			cartBogoRemoved: function (offerName) {
				let data = {};
				data.offerName = offerName;
				$this.ClickStream().push('CART_BOGO_REMOVED', { ...$this.ClickStream().defaults(), ...data });
			},
			placeOrderCampaign: function () {
				let data = {};
				data.orderPlaced = true;
				$this.ClickStream().push('RETAIL_CAMPAIGN_ORDER_PLACED', { ...$this.ClickStream().defaults(), ...data });
			},
			openLinkCampaign: function (contactNo) {
				let data = {};
				data.contactNo = contactNo;
				data.linkOpened = true;
				$this.ClickStream().push('RETAIL_CAMPAIGN_LINK_OPENED', { ...$this.ClickStream().defaults(), ...data });
			},
			errorReport: function (errmsg) {
				let data = {};
				data.errormsg = errmsg;
				data.errorInpageUrl = window.location.href;
				$this.ClickStream().push('REACT_ERROR', { ...$this.ClickStream().defaults(), ...data });
			},
			paymentFailure: function (totalValue) {
				let data = {};
				data.totalValue = totalValue;
				data.paymentMode = localStorage.getItem('paymentMode');
				$this.ClickStream().push('PAYMENT_FAILED', { ...$this.ClickStream().defaults(), ...data });
			},
			triggerClickStreamEvent: function (eventName, eventData) {
				$this.ClickStream().push(eventName, { ...$this.ClickStream().defaults(), ...eventData });
			},
			PersonalizedResult: function (varnts, issearch) {
				let data = {};
				if (issearch) { data.pageType = "SearchPage"; } else {
					data.pageType = 'CategoryListing';
				}
				if (window.pageType === 'clearance') {
					data.pageType = 'SalePage';
				}
				if (varnts) {
					for (let i = 0; i < varnts.length; i++) {
						let keyname = 'psnl_' + (i + 1);
						data[keyname] = varnts[i].id + ',' + varnts[i].rank;
					}
					$this.ClickStream().push('PERSONALIZED_CATALOG_RESULTS', { ...$this.ClickStream().defaults(true), ...data });
					HK.Cookies.set("ispersResults", 'true', .02);
				}
			},

			payment: function (data) {
				const cartItems = data.cartItemsQuantity && data.cartItemsQuantity.split(',');
				var cartSum = cartItems && cartItems.length > 0 && cartItems.reduce(function (a, b) {
					return parseInt(a) + parseInt(b);
				}, 0);
				const eventData = {
					allName: data.cartItemsNames || '',
					allfb_productid: data.cartItemIds || '',
					allBrands: data.allBrands || '',
					allcategory: data.allcategory || '',
					allleafNode: data.allleafNode || '',
					secondaryCategory: data.cartItemsSecondaryCatogories || '',
					seccategory: data.cartItemsSecondaryCatogories || '',
					shoppingCartTotalPayable: data.value || '',
					shoppingCartTotalQty: cartSum || '',
					pincode: data.pincode || '',
					allPrice: data.allPrice || '',
					allQuantity: data.allQuantity || '',
				}
				const datalayerObj = {
					...eventData,
					gtm_checkout_datalayer: {
						...eventData,
						imageUrl: data.imageUrl
					},
					event: "reactCheckoutEvent"
				}
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push(datalayerObj);
			},
			// checkout: function () {
			// 	$this.Adobe().checkout();
			// },
			dataLayerForCheckout: function (data, step) {
				let expressCheckout = HK.getSearchParmas().expressCheckout === "true";
				let flow = localStorage.getItem("Flow")
				let filter = '';
				if (HK.isMobile()) {
					filter = sessionStorage.getItem("mobilefilter")
				} else {
					filter = sessionStorage.getItem("filterClicked");
				}
				let products = [];
				let items = [];
				try {
					let dataCart = {}, cartItemIdsArr = [], cartItemNameArr = [], cartItemPriceArray = [], cartItemMrpArr = [], cartItemQuantityArr = [], cartItemSecCatArr = [];

					data && data.map((item) => {
						cartItemIdsArr.push(item.sv_id)
						cartItemNameArr.push(item.sv_nm)
						cartItemPriceArray.push(item.totOffPr / item.qty)
						cartItemMrpArr.push(item.totMrp / item.qty)
						cartItemQuantityArr.push(item.qty)
						cartItemSecCatArr.push(item.secondary_category)
						if (cartItemIdsArr.length) { dataCart.cartItemIds = cartItemIdsArr.join(','); }
						if (cartItemNameArr.length) { dataCart.cartItemsNames = cartItemNameArr.join(','); }
						if (cartItemPriceArray.length) { dataCart.cartItemsPrice = cartItemPriceArray.join(','); }
						if (cartItemMrpArr.length) { dataCart.cartItemsMrp = cartItemMrpArr.join(','); }
						if (cartItemQuantityArr.length) { dataCart.cartItemsQuantity = cartItemQuantityArr.join(','); }
						if (cartItemSecCatArr.length) { dataCart.cartItemsSecondaryCatogories = cartItemSecCatArr.join(','); }
						if (cartItemPriceArray.length && cartItemQuantityArr.length && cartItemPriceArray.length === cartItemQuantityArr.length) {
							let totalCartValue = 0;
							for (let i = 0; i < cartItemQuantityArr.length; i++) {
								totalCartValue += cartItemPriceArray[i] * cartItemQuantityArr[i];
							}
							dataCart.value = totalCartValue;
							dataCart.currency = 'INR';
						}
						let name = item.sv_nm
						let id = item.sv_id
						let price = item.totLP
						let brand = item.brand
						let category = item.catName
						let variant = "";
						let quantity = item.qty
						let dimension6 = "Cart Page";//pgtype
						let dimension7 = item.orderable ? "Out_of_Stock" : "In_Stock"; //instock
						let dimension8 = item.discount || 0 // dimension11
						let dimension9 = filter || ''
						let dimension10 = flow; // flow ad to cart
						const product = {
							'name': name,
							'id': id,
							'price': price,
							'brand': brand,
							'category': category,
							'variant': variant,
							'quantity': quantity,
							'dimension6': dimension6,
							'dimension7': dimension7,
							'dimension8': dimension8,
							'dimension9': dimension9,
							'dimension10': dimension10
						}
						const cartItem = {
							'item_name': name,
							'item_id': id,
							'price': price,
							'item_brand': brand,
							'item_category': category,
							'item_variant': variant,
							'quantity': quantity,
							'dimension6': dimension6,
							'dimension7': dimension7,
							'dimension8': dimension8,
							'dimension9': dimension9,
							'dimension10': dimension10
						}
						products.push(product);
						items.push(cartItem)
					})
					let EcomDataLayerStep1 = {
						'event': 'LS_MB_checkout_Step1',
						'ecommerce': {
							'checkout': {
								'actionField': { 'step': 1, 'option': 'Proceed to Buy' },
								products,
								items
							}
						}
					}
					let EcomDataLayerStep2 = {
						'event': 'LS_MB_checkout_Step2',
						'ecommerce': {
							'checkout': {
								'actionField': { 'step': 2, 'option': 'Select Address' },
								products,
								items
							}
						},
					}
					window.dataLayer = window.dataLayer || [];
					if (step == 1) {
						window.dataLayer.push(EcomDataLayerStep1);
					} else if (step == 2) {
						window.dataLayer.push(EcomDataLayerStep1);
						window.dataLayer.push(EcomDataLayerStep2);
					} else {
						if (expressCheckout) {
							window.dataLayer.push(EcomDataLayerStep1);
							window.dataLayer.push(EcomDataLayerStep2);
						}
						else {
							window.dataLayer.push(EcomDataLayerStep2);
						}
					}
					$this.googleAdsConversion().begincheckout_SuperMCC(dataCart)
					$this.googleAdsConversion().begincheckout_MBMCC(dataCart)
				} catch (err) {
					console.log('dataLayerForCheckout error')
				}
			},
		}
	},
	branchIo: {
		init: function () {
			let appKey = ISPROD ? 'key_live_kulDvr4NaQdrNlIHcg3EbhjlvEfTBZ2O' : 'key_test_oypCvy0VcVbwJbGRohXEeljksvgSx609';
			(function (b, r, a, n, c, h, _, s, d, k) { if (!b[n] || !b[n]._q) { for (; s < _.length;)c(h, _[s++]); d = r.createElement(a); d.async = 1; d.src = "https://cdn.branch.io/branch-latest.min.js"; k = r.getElementsByTagName(a)[0]; k.parentNode.insertBefore(d, k); b[n] = h } })(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]) } }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking getBrowserFingerprintId".split(" "), 0);
			window.__analytics.standBy.branchIo = window.__analytics.standBy.branchIo || [];
			let $this = this;
			if (!window.branch) {
				return;
			}
			window.branch.init(appKey, (err, data) => {
				// callback to handle err or data
				if (err) {
					return;
				} else {
					if (window.__analytics.standBy.branchIo.length > 0) {
						window.__analytics.standBy.branchIo.map((data) => {
							window.branch.logEvent(...data.data, function (err) { console.log(err); });
						})
						window.__analytics.standBy.branchIo = [];
					}
				}
			});
		},
		firstData: function () {
			if (!window.branch) {
				return;
			}
			window.branch.first((err, data) => {
				if (err) {
					return;
				}
				return data;
			});
		},
		returnDeepLink: function (data) {
			if (!window.branch) {
				return;
			}
			window.branch.link(data, (err, link) => {
				if (err) {
					return;
				}
				return link;
			});
		},
		setIdentity: function (data) {
			if (!window.branch) {
				return;
			}
			window.branch.setIdentity(data, (err, data) => {
				if (err) {
					return;
				}
				return data;
			})
		},
		logout: function () {
			if (!window.branch) {
				return;
			}
			window.branch.logout((err, data) => {
				if (err) {
					return;
				}
				return data;
			})
		},
		logEvent: function (event_name, event_data, content_items) {
			if (!window.branch) {
				window.__analytics.standBy.branchIo = window.__analytics.standBy.branchIo || [];
				window.__analytics.standBy.branchIo.push({ data: [event_name, event_data, content_items] });
				return;
			}
			window.branch.logEvent(event_name, event_data, content_items, (err, data) => {
				if (err) {
					return;
				}
				return data;
			})
		},
		link: function (linkData) {
			if (!window.branch) {
				return;
			}
			return new Promise(function (resolve, reject) {
				window.branch.link(linkData, (err, link) => {
					if (err) {
						return;
					}
					resolve(link);
				})
			})
		}
	},
	scopeBranch: function () {
		const $this = this;
		return {
			viewItem: function (productType, productdata) {
				let content_items;
				let event_and_custom_data = {
					"currency": "INR"
				}
				if (productType === "storeVariant") {
					content_items = {
						"$content_schema": "COMMERCE_PRODUCT",
						"$publicly_indexable": false,
						"$price": parseInt(productdata.offer_pr, 10),
						"$locally_indexable": true,
						"$quantity": 1,
						"$product_name": productdata.nm,
						"$product_brand": productdata.brName,
						"$product_category": productdata.page.pgBreadCrumbs[1].nm,
						"$condition": "NEW",
					};
				}
				if (productType === "pack") {
					content_items = {
						"$content_schema": "COMMERCE_PRODUCT",
						"$publicly_indexable": false,
						"$price": parseInt(productdata.packs.offer_pr, 10),
						"$locally_indexable": true,
						"$quantity": 1,
						"$product_name": productdata.packs.nm,
						"$product_category": "",
						"$condition": "NEW",
					}
				}

				$this.branchIo.logEvent(
					"VIEW_ITEM",
					event_and_custom_data,
					content_items,
					function (err) { console.log(err); }
				);
			},
			viewCart: function (odata) {
				if (odata) {
					let event_and_custom_data = {
						"currency": "INR"
					}
					let content_items = odata.map(ele => {
						return {
							"$content_schema": "COMMERCE_PRODUCT",
							"$publicly_indexable": false,
							"$price": ele.variantPrice,
							"$locally_indexable": true,
							"$quantity": ele.variantQuantity,
							"$product_name": ele.variantName,
							// "$product_category": ele.catName,
							"$condition": "NEW",
						};
					})
					$this.branchIo.logEvent(
						"VIEW_CART",
						event_and_custom_data,
						content_items,
						function (err) { console.log(err); }
					);
				}
				// $this.Adobe().viewCart();
			},
			viewListItems: function (odata, eventType, searchParams) {
				let event_and_custom_data = {
					"currency": "INR"
				};
				if (eventType === "SEARCH") {
					event_and_custom_data.search_query = searchParams;
				}
				let content_items = odata.variants.map(ele => {
					return {
						"$content_schema": "COMMERCE_PRODUCT",
						"$publicly_indexable": false,
						"$price": ele.offer_pr,
						"$locally_indexable": true,
						"$quantity": 1,
						"$product_name": ele.nm,
						// "$product_category": ele.catName,
						"$condition": "NEW",
					};
				})
				$this.branchIo.logEvent(
					eventType,
					event_and_custom_data,
					content_items,
					function (err) { console.log(err); }
				);
			},
			addToCartBuyNow: function (variantData, isPack) {
				let content_items;
				if (isPack) {
					content_items = [{
						"$content_schema": "COMMERCE_PRODUCT",
						"$publicly_indexable": false,
						"$price": variantData.offerPrice,
						"$locally_indexable": true,
						"$quantity": 1,
						"$product_name": variantData.name,
						"$condition": "NEW",
					}];
				} else {
					content_items = [{
						"$content_schema": "COMMERCE_PRODUCT",
						"$publicly_indexable": false,
						"$price": variantData.offerPrice,
						"$locally_indexable": true,
						"$quantity": 1,
						"$product_name": variantData.name,
						"$product_brand": variantData.brName,
						"$condition": "NEW",
					}];
				}
				let event_and_custom_data = {
					"currency": "INR"
				}
				$this.branchIo.logEvent(
					"ADD_TO_CART",
					event_and_custom_data,
					content_items,
					function (err) { console.log(err); }
				);
			},
			addToWishlist: function (variantData, isPack) {
				let content_items;
				content_items = [{
					"$content_schema": "COMMERCE_PRODUCT",
					"$publicly_indexable": false,
					"$price": variantData.totOffPr,
					"$locally_indexable": true,
					"$quantity": 1,
					"$product_name": variantData.sv_nm,
					"$product_brand": variantData.brand,
					"$condition": "NEW",
				}];
				let event_and_custom_data = {
					"currency": "INR"
				}
				$this.branchIo.logEvent(
					"ADD_TO_WISHLIST",
					event_and_custom_data,
					content_items,
					function (err) { console.log(err); }
				);
			},
			purchaseSuccess: function (odata) {
				let event_and_custom_data = {
					"transaction_id": odata.transactionId,
					"currency": "INR",
					"revenue": odata.totalValue, //total cart value
					"shipping": odata.shippingPrice
				}
				let content_items = odata.products.map(ele => {
					if (ele.id) {
						return {
							"$content_schema": "COMMERCE_PRODUCT",
							"$publicly_indexable": false,
							"$price": ele.totOffPr,
							"$locally_indexable": true,
							"$quantity": 1,
							"$product_name": ele.pckName,
							// "$product_category": ele.catName,
							"$condition": "NEW",
						};
					}
					else {
						return {
							"$content_schema": "COMMERCE_PRODUCT",
							"$publicly_indexable": false,
							"$price": ele.totOffPr,
							"$locally_indexable": true,
							"$quantity": 1,
							"$product_name": ele.sv_nm,
							"$product_brand": (ele.variants && ele.variants.length > 0) ? ele.variants[0].brand : "",
							// "$product_category": (ele.variants && ele.variants.length > 0) ? ele.variants[0].prCatName : "",
							"$condition": "NEW",
						};
					}
				})
				$this.branchIo.logEvent(
					"PURCHASE",
					event_and_custom_data,
					content_items,
					function (err) { console.log(err); }
				);
			},
			loginSignup: function (phNo, emailId) {
				var event_and_custom_data = {
					"phoneNumber": phNo || '',
					"emailId": emailId || ''
				};
				$this.branchIo.logEvent(
					"COMPLETE_REGISTRATION",
					event_and_custom_data,
					{},
					function (err) { console.log(err); }
				);
			},
			subscribe: function (emailId) {
				var event_and_custom_data = {
					"emailId": emailId
				};
				$this.branchIo.logEvent(
					"START_TRIAL",
					event_and_custom_data,
					{},
					function (err) { console.log(err); }
				);
			}
		}
	},
	// datalayer push for ADD to Cart & Buy now
	dataLayerForCartUpdate: function (productId, productData, isPack, eventName, PRESERVE_ON_RELOAD) {
		const fb_productPrice = productData && (productData.offerPrice || productData.offer_pr);
		let data = {
			fb_productPrice: fb_productPrice || "",
			shoppingCartTotalPayable: productData && productData.shoppingCartTotalPayable || 0,
			fb_productName: productData && productData.name || '',
			variantName: productData && productData.name || '',
			brand: productData.brand || (productData.brName || ''),
			category: productData.category || (productData.catName || ''),
			leafNode: productData.category || (productData.catName || ''),
			secondaryCategory: productData.secondaryCategory || (productData.secondary_category_name || ''),
			seccategory: productData.seccategory || (productData.secondary_category || ''),
			fb_productId: isPack ? 'PA-' + productId : 'VRNT-' + productId,
			//------
			google_tag_parma_new: {
				'ecomm_prodid': isPack ? 'PA-' + productId : 'VRNT-' + productId,
				'ecomm_totalvalue': fb_productPrice || "",
				'ecomm_pagetype': window.pageType
			}
		};
		const eventData = {
			...data,
			fb_cart_item: {
				...data,
				imageUrl: productData.imageUrl ? productData.imageUrl : (productData.img || ''),
				fb_qty: productData.quantity,
			},
			event: eventName || "reactCartUpdateEvent"
		}
		if (PRESERVE_ON_RELOAD) {
			UniversalAnalytics.PRESERVE_EVENTS_TO_STORAGE(eventData);
		} else {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push(eventData);
		}
	},
	// datalayer push for ADD to Cart & Buy now


	dataLayerForBlog: function (eventData) {
		let data = { ...eventData };
		data.event = "reactPageView";
		if (window.dataLayer) {
			window.dataLayer.push(data);
		}
	},
}
export default Analytics;