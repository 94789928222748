export const searchOverlayReducer = (state = null, action) => {
	if(action.type === 'SEARCH_OVERLAY_ACTION') {
		return action.payload;
    }

    return state;
}

export const searchDataReducer = (state = null, action) =>{
    if(action.type === 'SEARCH_DATA'){
        return action.payload;
    }
    return state;
}

export const searchInputReducer = (state = null, action) =>{
    if(action.type === 'SEARCH_INPUT'){
        return action.payload;
    }
    return state;
}

export const menuReducer=(state=null,action) => {
	if(action.type === 'footerMenu'){
		return action.payload;
	}
	return state;
}

export const headerPromotionalTextReducer=(state =0, action) => {
    if(action.type === 'HEADER_PROMOTIONAL_TEXT'){
        return action.payload;
    }
    return state;
}

export const menuPromotionalDataReducer=(state = '', action) => {
    if(action.type === 'HEADER_MENU_STRIP'){
        return action.payload;
    }
    return state;
}