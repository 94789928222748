import Analytics from './Analytics';
import config from './config';
import VerloopSDK from './Verloop';

var URL = require('url-parse');
const chekIfJsonOrString = (data) => {
    var ret = true;
    try {
        JSON.parse(data);
    } catch (e) {
        ret = false;
    }
    return ret;
};
export const __localStorageSet = (key, value) => {
    let temp;
    if ((value == null) || (value == undefined)) {
        return;
    }
    if (!value || (value && typeof value == "boolean") || (value && (typeof value == 'string' || value instanceof String))) {
        temp = value;
    } else {
        temp = JSON.stringify(value);
    }
    localStorage && localStorage.setItem(key, temp);
};

export const __localStorageGet = (key) => {
    let temp = null;
    let data = null;
    if (typeof window !== 'undefined') {
        data = localStorage.getItem(key);
        if (data && typeof data == "boolean") {
            temp = data;
        } else {
            let isJSON = (data && chekIfJsonOrString(data)) || false;
            if (isJSON) {
                temp = (data && JSON.parse(data)) || null;
            } else {
                temp = data;
            }
        }
    }

    return temp;
};

export const __localStorageDel = (key) => {
    if (localStorage) {
        localStorage.removeItem(key);
    }
};

export const __razorpay_gateway_UPI = (paymentModes) => {
    let temp = false;
    paymentModes.find(item => {
        if (item.id === 3012) {
            temp = true;
        }
    });
    return temp;
};
export const __getSearchParmas = (string, ssrQuery) => {
    let result = {};
    if (typeof window !== 'undefined') {
        string = string || window.location.href;
        var url = URL(string, true);
        result = url.query;
    } else {
        result = ssrQuery || {};
    }
    return result;
};

export const __getTabTitle = () => {
    let tabTitle = '';
    let tabId = __getSearchParmas().tabId || '';
    let orderDetail = __getSearchParmas().gIdFromFront || '';
    if (orderDetail) {
        tabTitle = 'ORDER DETAIL';
    } else {
        if (tabId) {
            if (tabId == 1) {
                tabTitle = 'PROFILE';
            } else if (tabId == 4) {
                tabTitle = 'MY ADDRESSES';
            } else if (tabId == 5) {
                tabTitle = 'MY ORDERS';
            } else if (tabId == 7) {
                tabTitle = 'Gritzo Cash';
            } else if (tabId == 19) {
                tabTitle = 'MY SAVED CARD';
            }
        } else {
            tabTitle = '';
        }
    }

    return tabTitle;
};

export const __getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
};
export const __showOpenInApp = () => {
    let temp = false;
    let isBajajPay = __isBajajPay();
    if (isBajajPay) {
        temp = false;
    } else {
        temp = true;
    }
    return temp;
};
export const __isBajajPay = () => {
    let temp = false;
    let utm_source = __getSearchParmas().utm_source;
    if (utm_source && utm_source.toLowerCase() == config.utm_source.bajajpwa || (typeof sessionStorage === 'object' && sessionStorage.getItem('utm_source') === config.utm_source.bajajpwa)) {
        temp = true;
    }
    return temp;
};

export const __getCookie = (cname = '') => {
    if (typeof window !== 'undefined') {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }
    }
    return false;
};

export const __setCookie = (cname, cvalue, exdays, setDefaultExpiry, inHrs) => {
    if (typeof window !== 'undefined') {
        let _domain = window.location.hostname.indexOf('gritzo.com') > -1 ? window.location.hostname.match(/[^.]+\.[^.]+$/) : null;
        _domain = (_domain !== null && _domain.indexOf('qa') === -1 && _domain.indexOf('stag') === -1) ? '.' + _domain[0] : false;
        if (inHrs) {
            let expires = "expires=" + exdays.toUTCString();
            if (_domain)
                document.cookie = cname + "=" + cvalue + "; " + expires + ";domain=" + _domain + ";path=/";
            else
                document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
        } else {
            const d = new Date();
            if (setDefaultExpiry) {
                d.setDate(exdays);
            } else {
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            }
            let expires = "expires=" + d.toGMTString();
            if (_domain)
                document.cookie = cname + "=" + cvalue + "; " + expires + ";domain=" + _domain + ";path=/";
            else
                document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
        }

    }
};
export const __showChatBot = (isChatIcon = false) => {
    let body = document.body;
    if (isChatIcon && isChatIcon == true) {
        body.classList.remove("no-chat-button");
    } else {
        body.classList.add("no-chat-button");
    }
};

export const __setLoyaltyDetail = (hkUserLoyaltyDetailsDto) => {
    const loyaltyLevelName = (hkUserLoyaltyDetailsDto && hkUserLoyaltyDetailsDto.loyaltyLevelName) || '';
    __localStorageDel("loyaltyLevelName");
    if (loyaltyLevelName) {
        __localStorageSet("loyaltyLevelName", loyaltyLevelName);
    }
};

export const __loggedIn = () => {
    const _cookie = __getCookie('assumeAuth') || __getCookie('authtoken');
    const _localValue = __getCookie('assumeId') || __localStorageGet('isUserLoggedIn');
    let userStatus = _localValue && _cookie && !__localStorageGet('isTMPUser') ? _localValue : false;
    userStatus = userStatus !== 'false' ? userStatus : false;
    return userStatus;
};


export const __removeQueryParam = (key, sourceURL) => {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
};
export const __appendQueryparam = (URL, key, value) => {
    URL += (URL.indexOf('?') > -1 ? '&' : '?') + `${key}=` + value;
    return URL;
};

export const __isOrderDelayed = (data) => {
    if (data && !data.estDeliveryDate) {
        return false;
    }
    const edd = new Date(data.estDeliveryDate).toDateString();
    const todaysDate = new Date().toDateString();
    const eddEpoch = new Date(data.estDeliveryDate).setHours(0, 0, 0, 0);
    const todayEpoch = new Date().setHours(0, 0, 0, 0);

    if (edd === todaysDate) {
        const currentHour = new Date().getHours();
        if (currentHour >= 21) {
            return true;
        }
    } else if (todayEpoch > eddEpoch) {
        return true;
    }
    return false;
};

export const __dataLayer = (args, pageType) => {
    let _datalayer = {
        'uH': 'guest',
        'uM': 'n.a.',
        'uG': 'n.a.',
        'oC': 'n.a.',
        'contact': 'n.a.',
        'firstName': 'n.a.',
        'birthDt': 'n.a.',
        'pageType': pageType || window.pageType,
        'pageUrl': window.location.href,
        'primaryCategory': '',
        'secondaryCategory': '',
        'tertiaryCategory': '',
        'allCats': [],
        'allNavKeys': [],
        'brand': '',
        'variantId': '',
        'productId': '',
        'cohort': '',
        'variantOfferPrice': '',
        'variantMrp': '',
        'variantDiscount': '',
        'variantName': '',
        'variantImageM': '',
        'variantImageS': '',
        'primaryMenu': '',
        'secondaryMenu': '',
        'tertiaryMenu': '',
        'navKey': '',
        'parentNavKey': '',
        'oos': '',
        'env': 'prod',
        'signup': '',
        'login': '',
        'guest': '',
        'canonicalUrl': 'https://www.gritzo.com',
        'errorCode': '',
        'browserCompat': 'true',
        'svTags': [],
        'cTags': [],
        'bTags': [],
        'isMobile': __isMobile(),
        'vendorName': '',
        'appliedOffer': '',
        'abTestMode': '',
        'ShoppingCartItemsWebEngage': [],
        'packDetails': {},
        'primaryAttributeName': "",
        'primaryAttributeValue': "",
        'secondaryAttributeName': "",
        'secondaryAttributeValue': "",
        'orderSuccessDetailsWebengage': {
            'transactionId': '',
            'totalValue': 0.0,
            'paymentMode': '',
            'shippingPrice': 0.0,
            'leafNode': "",
            'products': [
            ],
            'brand': "",
            'category': "",
            'name': "",
            'variantId': "",
            'packId': ""
        },
        'newSession': true,
        'lpBrand': '',
        'lpPrimCat': '',
        'lpPrimMenu': '',
        'google_tag_parma_new': {
            'ecomm_prodid': '',
            'ecomm_pagetype': pageType || window.pageType,
            'ecomm_totalvalue': ''
        }
    };
    let _userData = __localStorageGet('userData');
    let _localValue = __localStorageGet('_datalayer') || [];
    let __datalayer = { ..._datalayer, ..._localValue, ...args, ..._userData };

    if (__datalayer.event !== undefined && args.event === undefined)
        delete __datalayer.event;
    __localStorageSet('_datalayer', JSON.stringify(__datalayer));
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(__datalayer);
};

export const __deleteCookie = (name = '') => {
    if (typeof window !== 'undefined') {
        let _domain = window.location.hostname.indexOf('gritzo.com') > -1 ? window.location.hostname.match(/[^.]+\.[^.]+$/) : null;
        _domain = (_domain !== null && _domain.indexOf('qa') === -1 && _domain.indexOf('stag') === -1) ? '.' + _domain[0] : false;
        if (_domain)
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + _domain + ";path=/";
        else
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
    }
};

export const __removeSiteStorage = () => {
    __deleteCookie('authtoken');
    __deleteCookie('isAssume');
    __deleteCookie('origId');
    __deleteCookie('assumeAuth');
    __deleteCookie('assumeId');
    __deleteCookie('isRetailAssumed');
    __deleteCookie('isUserLoggedIn');
    __deleteCookie('isTMPUser');
    __localStorageDel("loyaltyLevelName");
    __localStorageDel('isUserLoggedIn');
    __localStorageDel('_isUserLoggedIn');
    __localStorageDel('userData');
    __localStorageDel('oelp_data');
    __localStorageDel('isTMPUser');
    __localStorageDel('activeAddressId');
};

export const __updateGaEventNme = (eventName, data) => {
    let eventData = {};
    if (eventName) {
        eventData.event = eventName;
    }
    if (data) {
        eventData = { ...eventData, ...data };
    }
    if (typeof window !== "undefined") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eventData);
    }
};

export const __triggerLogoutEvent = () => {
    VerloopSDK.logout();
    __removeSiteStorage();
    Analytics.Scope().logout();
    let dataLayerobj = {
        'uH': 'guest',
        'uM': 'n.a.',
        'uG': 'n.a.',
        'oC': 'n.a.',
        'contact': 'n.a.',
        'firstName': 'n.a.',
        'birthDt': 'n.a.',
    };
    __dataLayer(dataLayerobj, window.pageType);
    __updateGaEventNme('reactLogout');
    let loggedInRequiredPages = [
        "cart", "selectAddress", "selectPayment", "paymentSuccess", config.pageTypes.mbDataCapture, config.pageTypes.gritzo.growthTrack
    ];
    if (loggedInRequiredPages.indexOf(window.pageType) > -1) {
        // this.props.history.push("/");
        window.location.href = "/";
    } else {
        window.location.reload();
    }
};

export const __chatActiveHours = () => {
    let flag = false;
    let hour = new Date().getHours();
    if (hour > 9 && hour < 19) {
        flag = true;
    }
    return flag;
};

export const __chatSupportPopup = (bool, data) => {
    const event = new CustomEvent("chatBotPopup", { detail: { isTriggered: bool, orderData: data } });
    window.dispatchEvent(event);
};

export const __chatFallbackHandler = (bool) => {
    const event = new CustomEvent("chatFallback", { detail: { isTriggered: bool } });
    window.dispatchEvent(event);
    if (bool === false) {
        __chatSupportPopup(false);
    }
};
export const __chatBotData = (chatBotData) => {
    const keys = ['title', 'message', 'support', 'subTitle', 'subTitle', 'team', 'email'];
    let obj = {
        "title": '',
        "message": '',
        "support": '',
        "subTitle": '',
        "team": '',
        "email": '',
    };

    if (chatBotData && chatBotData.length > 0) {
        chatBotData.forEach((item) => {
            const key = item.name;
            if (key && keys.indexOf(key) > -1) {
                obj[key] = item.value;
            }
        });
    }
    return obj;
};
export const __commaSeparator = (num) => {
    var x = num;
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
};

export const __isMobile = (props) => {
    let check = false;
    if (typeof window === 'undefined') {
        check = props && props.isMobile ? true : false;
    } else {
        (function (a) {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(a))
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
    }
    return check;
};

export const __getImage = (path) => {
    let _cdns = process.env.NEXT_PUBLIC_CDN_URLS.split(',');
    return _cdns[0] + '/static/media' + path;
};
export const __isCallBackExist = (method) => {
    let temp = false;
    if (method && typeof method === 'function') {
        temp = true;
    }
    return temp;
};
export const __createOfferData = (offers) => {
    let tempOffers = [];
    offers && offers.length > 0 && offers.map(eachOffers => {
        if (eachOffers.issuerOfferMapping &&
            eachOffers.issuerOfferMapping.name &&
            eachOffers.issuerOfferAdditionalCriteriaDTO &&
            eachOffers.issuerOfferAdditionalCriteriaDTO.acquisitionChannelSources &&
            eachOffers.issuerOfferAdditionalCriteriaDTO.acquisitionChannelSources.length > 0)
            eachOffers.issuerOfferAdditionalCriteriaDTO.acquisitionChannelSources.map(source => {
                source = (source || '').toLowerCase();
                let affeliate = (__getCookie("affiliateName") || '').toLowerCase();
                if (source === affeliate) {
                    tempOffers.push({
                        do: eachOffers.do,
                        id: eachOffers.id,
                        off_nm: eachOffers.nm,
                        offerImageLink: eachOffers.bankImageUrl,
                        offerString: eachOffers.offerDesc,
                        payMode: eachOffers.issuerOfferMapping.name,
                        termAndCondtion: eachOffers.issuerOfferAdditionalCriteriaDTO.termAndCondtion,
                        specialOffer: true,
                        issuerCode: eachOffers.issuerOfferMapping.issuerCode,
                        isAdditionalOffer: true,
                        discount: eachOffers.discount,
                    });
                }
            });
    });
    return tempOffers;
};
export const __dateBefore=(d, days)=>{
    let temp = new Date(d);
    temp = new Date(temp.setDate(temp.getDate() + days));
    return temp;
};

export const __dateAfter=(d, days)=>{
    let temp = new Date(d);
    temp = new Date(temp.setDate(temp.getDate() + days));
    return temp;
};

export const __isEmptyObj = (obj) => {
    try {
        return Object.keys(obj).length === 0;
    } catch (err) {
        console.log(err);
    }
};

export const __getTATIcon = (messageTat, isCart) => {
    if (messageTat && messageTat.toLocaleLowerCase() && messageTat.toLocaleLowerCase().includes('today')) {
        return {
            icon: isCart? '/delivery/same_day_del_cart.svg' :'/delivery/same_day_del.svg',
            class: 'active'
        };
    } else {
        return {
            icon: isCart? '/cart/delivery.svg' : `/delivery/green-tick.svg`,
            class: 'non-active'
        };
    }
};
