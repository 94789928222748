const initialState = {
    allArticles: null,
    exerciseArticles: null,
    fuelArticles: null,
    dietArticles: null,
}
export const ArticlesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_ARTICLES':
            return {
                ...state,
                allArticles: action.payload
            };
        case 'EXERCISE_ARTICLES':
            return {
                ...state,
                exerciseArticles: action.payload
            };
        case 'FUEL_ARTICLES':
            return {
                ...state,
                fuelArticles: action.payload
            };
        case 'DIET_ARTICLES':
            return {
                ...state,
                dietArticles: action.payload
            };
    }
    return state
}