const initialState = {
    firstFoldData: null,
    variantModal: false,
    userWeightSelect:null
}
export const PDPReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FIRSTFOLD':
            return {
                ...state,
                firstFoldData: action.payload
            };
        case 'VARIENT_MODAL':
            return {
                ...state,
                variantModal: action.payload
            };
        case 'USER_WEIGHT_SELECT':
            return {
                ...state,
                userWeightSelect: action.payload
            };
    }
    return state
}

export const tatReducer = (state = {}, action) => {
    switch (action.type) {
        case 'TAT_DATA':
            return action.payload;
    }
    return state
}

export const tatMSGReducer = (state = {}, action) => {
    switch (action.type) {
        case 'TAT_MSG_DATA':
            return action.payload;
    }
    return state;
};