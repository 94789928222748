export const isLoginPopupReducer = (state=null,action)=>{
    switch(action.type){
        case "LOGIN_POPUP_CLOSE" :
            state = action.payload;
        break;
        case "LOGIN_POPUP_OPEN" :
            state = action.payload;
        break;
        default:
        break;
    }
    return state;
}

export const loginScreenReducer = (state=null,action)=>{
    switch(action.type){
        case "LOGIN_SCREEN_ACTION" :
            state = action.payload;
        break;
        default:
        break;
    }
    return state;
}