export const singleLineFooterReducer = (state = null, action) => {
    if (action.type === "SHOW_SINGLE_LINE_FOOTER") {
        return action.payload;
    }
    return state;
}
export const FooterActionReducer = (state = true, action) => {
    if (action.type === "SHOW_FOOTER_ACTION") {
        return action.payload;
    }
    return state;
}