export const getPaymentModeReducer  = (state=null,action)=>{
    if(action.type === "GET_PAYMENT_MODE"){
        return action.payload
    }
    return state;
}

export const codAllowedReducer  = (state=null,action)=>{
    if(action.type === "IS_COD_ALLOWED"){
        return action.payload
    }
    return state;
}

export const paymentPrepareReducer  = (state=null,action)=>{
    if(action.type === "CART_PAYMENT_ACTION"){
        return action.payload
    }
    return state;
}

export const codDataReducer = (state = null,action) =>{
    if(action.type === "COD_DATA"){
        return action.payload
    }
    return state;
}

export const cardOfferAvldReducer = (state = null,action) =>{
    if(action.type === "CARD_OFFER_AVAILED"){
        return action.payload
    }
    return state;
}

export const offerIssuerReducer = (state = null,action) =>{
    if(action.type === "OFFER_ISSUER_MAPPING"){
        return action.payload
    }
    return state;
}

export const issuerAvldReducer = (state = null,action) =>{
    if(action.type === "ISSUER_IDENTIFIRE"){
        return action.payload
    }
    return state;
}
export const checkoutStepsReducer = (state = null,action) =>{
    if(action.type === "CHECKOUT_STEPS"){
        return action.payload
    }
    return state;
}

export const PaymentPropsReducer = (state = null,action)=>{
    if(action.type === "PAYMENT_PROPS_VALUE"){
        return action.payload
    }
    return state
}