import { combineReducers } from "redux";
/* home reducer */
import { HomeReducer, LatestOrderReducer } from 'components/Home/store/reducer.js';
import {searchActiveReducer} from 'components/common/header/contains/Search/reducer.js'
import {
	videoShowReducer, cartReducer, mobMenuReducer, mobSearchOpenReducer, mobMenuOpenReducer, searchStateReducer, headerTransparentReducer,
	MenuReducer,MBCashAvailableReducer
} from 'components/common/header/reducer.js';
import { searchOverlayReducer, searchDataReducer, searchInputReducer, menuReducer, headerPromotionalTextReducer, menuPromotionalDataReducer } from 'components/common/header/desktop/reducer';
import { userDetailReducer, isLogedInReducer, isAssumeReducer, isReferEarnPopupShownReducer, compareItemCountReducer, comparePopupReducer, tbRedeemPopUpReducer, tbConcernNameReducer, tbUserTabReducer, tbReferAndEarnTnCReducer, tbsCategoriesReducer } from "factory/store/reducers/";
import { isLoginPopupReducer, loginScreenReducer } from 'components/common/login/reducer';
import { pageTypeReducer } from 'components/pageTypeReducer';
import { singleLineHeaderReducer, singleLineHeaderLinkToReducer, headerTextReducer, showHeaderIconsReducer } from 'components/common/header/SingleLineHeader/reducer';
import { singleLineFooterReducer, FooterActionReducer } from 'components/common/footer/SingleLineFooter/reducer';
import { showAddressFormReducer, addressFormDataReducer, addressListReducer, ActiveAdddressReducer } from 'components/Address/reducer';
import { PDPReducer, tatMSGReducer, tatReducer } from 'components/Pdp/Store/reducer.js';
// cart reducer
import { cartOrderSummaryReducer, cartVariantReducer, cartPackReducer, cartOfferReducer, cartAppliedOfferReducer, cartHeaderSummaryReducer, cartAddressReducer, appliedMBCashChangeReducer ,orderSummaryPopUpReducer, paymentOfferReducer} from 'components/Cart/reducer';
// payment reducer
import { getPaymentModeReducer, codAllowedReducer, paymentPrepareReducer, codDataReducer, checkoutStepsReducer, cardOfferAvldReducer, PaymentPropsReducer, offerIssuerReducer } from 'components/Payment/reducer';
// order success reducer
import { isMuscleblazeBrandReducer } from 'components/OrderSuccess/reducer';
import { ArticlesReducer } from 'components/common/articles/store/reducer.js';
import { filterSelectReducer, defaultFilterSelectReducer, priceRangeDataReducer, excludeOOSReducer } from 'components/Category/mobile/mobReducer';
//Reviews
import { serverReducers } from '../reducers/serverReducers';
import { reviewImagesReducer, ReviewTabReducer, selectedSortReducer } from "components/ReviewDataNew/Reducer";
// import { childNameReducer,phoneNumberReducer,genderReducer } from '../../../components/PersonalizationEngine/reducer/index';
const allReducers = combineReducers(
	{
		// *** search reducer
		searchActiveReducer,

		//*** header reducer
		headerPromotionalTextReducer: headerPromotionalTextReducer,
		menuPromotionalDataReducer:menuPromotionalDataReducer,
		HomeReducer: HomeReducer,
		LatestOrderReducer: LatestOrderReducer,
		CARTReducer: cartReducer,
		searchOverlayReducer: searchOverlayReducer,
		searchDataReducer: searchDataReducer,
		searchInputReducer: searchInputReducer,
		isLogedInReducer: isLogedInReducer,
		userDetailReducer: userDetailReducer,
		menuReducer: menuReducer,
		mobMenuReducer: mobMenuReducer,
		MBCashAvailableReducer:MBCashAvailableReducer,
		isLoginPopupReducer: isLoginPopupReducer,
		isAssumeReducer: isAssumeReducer,
		pageTypeReducer: pageTypeReducer,
		isReferEarnPopupShownReducer: isReferEarnPopupShownReducer,
		loginScreenReducer: loginScreenReducer,
		mobSearchOpenReducer: mobSearchOpenReducer,
		mobMenuOpenReducer: mobMenuOpenReducer,
		searchStateReducer: searchStateReducer,
		singleLineHeaderReducer: singleLineHeaderReducer,
		singleLineHeaderLinkToReducer: singleLineHeaderLinkToReducer,
		singleLineFooterReducer: singleLineFooterReducer,
		FooterActionReducer: FooterActionReducer,
		headerTextReducer: headerTextReducer,
		showHeaderIconsReducer: showHeaderIconsReducer,
		ArticlesReducer: ArticlesReducer,
		// cart reducer
		cartOrderSummaryReducer: cartOrderSummaryReducer,
		cartVariantReducer: cartVariantReducer,
		cartPackReducer: cartPackReducer,
		cartOfferReducer: cartOfferReducer,
		paymentOfferReducer: paymentOfferReducer,
		cartAppliedOfferReducer: cartAppliedOfferReducer,
		cartHeaderSummaryReducer: cartHeaderSummaryReducer,
		cartAddressReducer: cartAddressReducer,
		orderSummaryPopUpReducer:orderSummaryPopUpReducer,
		// address
		showAddressFormReducer: showAddressFormReducer,
		addressFormDataReducer: addressFormDataReducer,
		addressListReducer: addressListReducer,
		ActiveAdddressReducer: ActiveAdddressReducer,
		// payment
		getPaymentModeReducer: getPaymentModeReducer,
		codAllowedReducer: codAllowedReducer,
		paymentPrepareReducer: paymentPrepareReducer,
		codDataReducer: codDataReducer,
		checkoutStepsReducer: checkoutStepsReducer,
		PaymentPropsReducer: PaymentPropsReducer,
		cardOfferAvldReducer: cardOfferAvldReducer,
		offerIssuerReducer: offerIssuerReducer,
		// order success
		isMuscleblazeBrandReducer: isMuscleblazeBrandReducer,
		headerTransparentReducer: headerTransparentReducer,
		filterSelectReducer: filterSelectReducer,
		defaultFilterSelectReducer: defaultFilterSelectReducer,
		priceRangeDataReducer: priceRangeDataReducer,
		excludeOOSReducer: excludeOOSReducer,
		//pdp mob video
		videoShowReducer: videoShowReducer,
		MenuReducer: MenuReducer,
		//compare mob
		compareItemCountReducer: compareItemCountReducer,
		comparePopupReducer: comparePopupReducer,
		// Trubasics
		tbRedeemPopUpReducer: tbRedeemPopUpReducer,
		tbConcernNameReducer: tbConcernNameReducer,
		tbUserTabReducer: tbUserTabReducer,
		tbReferAndEarnTnCReducer: tbReferAndEarnTnCReducer,
		tbsCategoriesReducer: tbsCategoriesReducer,
		/* pdp reducer */
		PDPReducer: PDPReducer,
		tatReducer,
		tatMSGReducer,
		appliedMBCashChangeReducer,
		/* Review Reducer*/
		selectedSortReducer:selectedSortReducer,
		reviewImagesReducer:reviewImagesReducer,
		ReviewTabReducer:ReviewTabReducer,
		serverReducers,
	}
);
export default allReducers;
