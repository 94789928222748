const initialState = {
  activeReviewIndex: -1,

};
export const selectedSortReducer = (state = "", action) => {
  if (action.type === "SELECTED_SORT") {
    return action.payload;
  }

  return state;
};

export const reviewImagesReducer = (state = "", action) => {
  if (action.type === "REVIEW_IMAGES") {
    return action.payload;
  }

  return state;
};

export const ReviewTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REVIEW_TAB_SWITCH':
      return {
        ...state,
        activeReviewIndex: action.payload
      };
    default:
      return {
        ...state
      };
  }
};