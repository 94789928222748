const initialState = {
    HomeInitData: null,
    OrderDetails: null
}
export const HomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HOME_INIT':
            return {
                ...state,
                HomeInitData: action.payload
            };

    }
    return state
}


export const LatestOrderReducer = (state = initialState, action) => {
    if(action.type === "LATEST_ORDER_ACTION"){
        return {
            ...state,
            OrderDetails: action.payload
        };
    }
    return state;
}

